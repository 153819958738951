import {ChangeDetectionStrategy, Component, computed, Injector, Signal} from '@angular/core';
import {ValidatedForm} from "../../util/validated-form";
import {AbstractControl, ReactiveFormsModule, UntypedFormBuilder, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router, RouterLink} from "@angular/router";
import {delay, finalize, map} from "rxjs/operators";
import {StateService} from "../../services/state.service";
import {UtilService} from "../../services/util.service";
import {Constants} from "../../util/constants";
import {SessionService} from "../../services/session.service";
import {BusyService} from "../../services/busy.service";
import {NotificationService} from "../../services/notification.service";
import {MatButton} from '@angular/material/button';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {toSignal} from "@angular/core/rxjs-interop";
import {FormSignals} from "../../util/form-signals";

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, MatFormField, MatInput, MatError, MatLabel, MatCheckbox, MatButton, RouterLink]
})
export class LoginComponent extends ValidatedForm {

    isTrustedDevice: Signal<boolean>;

    formSignals: FormSignals;

    constructor(protected override fb: UntypedFormBuilder,
                private router: Router,
                private state: StateService,
                private utilService: UtilService,
                private sessionService: SessionService,
                private busyService: BusyService,
                private notificationService: NotificationService,
                private authService: AuthService) {
        super(fb);
        this.initForm({
            email: {
                value: '',
                validators: [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)],
                messages: {'required': Constants.ERR_REQUIRED, 'pattern': Constants.ERR_INVALID_EMAIL}
            },
            password: {
                value: '',
                validators: [Validators.required],
                messages: {'required': Constants.ERR_REQUIRED}
            },
            code: {
                value: '',
                validators: [Validators.required, Validators.pattern(/\d{6}/)],
                messages: {'required': Constants.ERR_REQUIRED, 'pattern': Constants.ERR_INVALID_2FA}
            },
            trustThisDevice: { value: false }
        });
        if (this.authService.isLoggedIn()) {
            this.authService.logOut().subscribe(() => {
                console.warn("Forced logout");
                this.state.clearAll();
                this.sessionService.clearSession();
            });
        }

        this.formSignals = new FormSignals(this.form,
            {
                email: this.fields!['email'].messages!,
                password: this.fields!['password'].messages!,
                code: this.fields!['code'].messages!
            },
            undefined,
            Constants.DEFAULT_DEBOUNCE_DELAY + 200
        );

        this.isTrustedDevice = computed(() => {
            const email = this.formSignals.value('email')();
            // If we don't have an email ready yet, assume it will be trusted
            if (!email || !this.formSignals.valid('email')())
                return true;
            return this.authService.hasTrustToken(email);
        });
    }

    canSubmit = computed(() => {
        return !this.busyService.isBusy() &&
                this.formSignals.valid('email')() &&
                this.formSignals.valid('password')() &&
               (this.isTrustedDevice() || this.formSignals.valid('code')());
    });

    submit() {
        this.busyService.showBusy();
        let fields: any = this.fields;
        const email = fields['email'].value;
        const password = fields['password'].value;
        const code = fields['code'].value || undefined;
        const trustThisDevice: boolean = fields['trustThisDevice'].value;
        const pwa: boolean = window.matchMedia('(display-mode: standalone)').matches;
        this.authService.logIn(email, password, code, trustThisDevice, pwa).pipe(
            finalize(() => {
                this.busyService.showNotBusy();
                this.state.clear('targetUrl');
            })
        ).subscribe({
            next: () => {
                this.state.set('email', email);
                const targetUrl = this.state.get('targetUrl')() || '/domains';
                this.router.navigateByUrl(targetUrl).then();
                this.sessionService.restartSession();
                this.notificationService.connect();
            },
            error: this.utilService.getErrorHandler("Login error")
        });
    }
}
