/** Globally useful constants or pseudo-constants
 */
export class Constants {
    static readonly DEFAULT_DEBOUNCE_DELAY = 500;
    static readonly ERR_REQUIRED = 'Required';
    static readonly MIN_PASSWORD_LENGTH = 8;
    static readonly ERR_PASSWORD_LENGTH = `The password must have at least ${Constants.MIN_PASSWORD_LENGTH} characters`;
    static readonly ERR_PASSWORD_LETTERS_DIGITS = "The password must contain letters and digits";
    static readonly ERR_PASSWORD_SPACES = "The password can't contain spaces";
    static readonly ERR_PASSWORD_MISMATCH = "The passwords don't match";
    static readonly ERR_INVALID_EMAIL = 'Must be a valid email address';
    static readonly ERR_INVALID_2FA = "Invalid 2FA code: must be 6 digits";
    static readonly EMAIL_REGEX =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    static readonly DOMAIN_REGEX = /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)+$/i;

    static readonly US_TIMESTAMP_FORMAT = new Intl.DateTimeFormat('en-US', {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour12: true, hour: 'numeric', minute: '2-digit', second: '2-digit'
    });
}
