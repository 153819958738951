import {FormGroup} from "@angular/forms";
import {Injector, signal, Signal} from "@angular/core";
import {SignalMap} from "./signal-map";
import {StringMap} from "./interfaces";
import {toSignal} from "@angular/core/rxjs-interop";
import {delay, map} from "rxjs/operators";

// Maps each control name to a map from error code to error message, for each possible validated error
// It also provides signals for global form status detection
export interface ErrorMessageMap {
    [name:string] : StringMap;
}

export class FormSignals {

    formValid: Signal<boolean>;
    formErrors: Signal<string>;
    private errorMap = new SignalMap<string>();
    private valueMap = new SignalMap();
    private validMap = new SignalMap<boolean>;
    private readonly injector: Injector;

    constructor(private fg: FormGroup,
                private errorMessageMap: ErrorMessageMap,
                formErrorMap?: StringMap,
                private errorDelay: number = 0) {

        this.injector = Injector.create({providers: []});

        this.formValid = toSignal(this.fg.valueChanges.pipe(
            map(() => this.fg.valid)
        ), {initialValue: this.fg.valid, injector: this.injector});

        if (!formErrorMap)
            this.formErrors = signal('');
        else {
            this.formErrors = toSignal(this.fg.statusChanges.pipe(
                map(() => {
                    if (this.fg.dirty && !this.fg.valid && !this.fg.pending) {
                        let errors: string[] = [];
                        Object.keys(formErrorMap).forEach((messageName: string) => {
                            if (this.fg?.hasError(messageName))
                                errors.push(formErrorMap[messageName]);
                        });
                        return errors.join("; ");
                    } else
                        return '';
                })
            ), {initialValue: '', injector: this.injector});
        }
    }

    errors(controlName: string): Signal<string> {
        if (!this.errorMap.has(controlName)) {
            const control = this.fg.get(controlName);
            if (!control)
                throw new Error(`Invalid control name for errorSignal: ${controlName}`)
            const sig = toSignal(control.statusChanges.pipe(
               delay(this.errorDelay),
               map(() => {
                   if (control.errors) {
                       let controlErrors= Object.keys(control.errors).map((key) => this.errorMessageMap[controlName][key]);
                       return controlErrors.join('; ');
                   }
                   return '';
               })
            ), {initialValue: '', injector: this.injector});
            this.errorMap.set(controlName, sig);
        }
        return this.errorMap.get(controlName)!;
    }

    value<T=string>(controlName: string): Signal<T> {
        if (!this.valueMap.has(controlName)) {
            const control = this.fg.get(controlName);
            if (!control)
                throw new Error(`Invalid control name for valueSignal: ${controlName}`)
            const sig = toSignal(control.valueChanges.pipe(
                map(() => control.value)
            ), {initialValue: control.value, injector: this.injector});
            this.valueMap.set(controlName, sig);
        }
        return this.valueMap.get(controlName)!;
    }

    valid(controlName: string): Signal<boolean> {
        if (!this.validMap.has(controlName)) {
            const control = this.fg.get(controlName);
            if (!control)
                throw new Error(`Invalid control name for validSignal: ${controlName}`)
            const sig = toSignal(control.valueChanges.pipe(
                map(() => control.valid)
            ), {initialValue: control.valid, injector: this.injector});
            this.validMap.set(controlName, sig);
        }
        return this.validMap.get(controlName)!;
    }

}