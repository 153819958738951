import { Component } from '@angular/core';
import { BusyLauncherComponent } from './controls/busy-launcher/busy-launcher.component';
import { RouterOutlet } from '@angular/router';
import { NavBarComponent } from './controls/nav-bar/nav-bar.component';
import { HeaderComponent } from './controls/header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [HeaderComponent, NavBarComponent, RouterOutlet, BusyLauncherComponent]
})
export class AppComponent {
  title = 'client';
}
