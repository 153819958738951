
export enum Operation {
    ChangeNameServers = 1,
    ChangeContactInfo = 2,
    Create = 3,
    Delete = 4,
    AvailabilityCheck = 7,
    SyncFromRegistry = 11,
    SubmitTransfer = 12,
    Unlock = 13,
    CompleteTransfer = 14,
    PrivacyInfo = 33,
    ApproveTransfer = 35,
    BulkRenew = 38,
    BulkRestore = 39,
    BulkRestoreReport = 40,
    Lock = 101,
    GetInfo = 102
}
