import {Injectable, signal} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class BusyService {

    private count: number = 0;
    private busySubject: Subject<boolean> = new Subject<boolean>();

    private _isBusy = signal(false);
    isBusy = this._isBusy.asReadonly();

    showBusy() {
        if (++this.count == 1) {
            this._isBusy.set(true);
            this.busySubject.next(true);
            document.body.classList.add("busy");
        }
    }

    showNotBusy() {
        if (--this.count == 0) {
            this._isBusy.set(false);
            this.busySubject.next(false);
            document.body.classList.remove("busy");
        }
    }

}
