<h1>Buy Domain</h1>

<div class="info_row">
    <span class="label">Domain name: </span>
    <span class="domain">{{domainName}}</span>
</div>

@if (available() === undefined) {
    <div class="button_row">
        <button mat-raised-button
                color="accent"
                (click)="checkAvailability()">Check Availability</button>

        <button mat-raised-button
                color="primary"
                (click)="cancelDomain()">Cancel</button>
    </div>
} @else if (available()) {
    <div>
        <p>Domain is available for purchase!</p>

        <p>You may select an alternate name server group, or just use the default, and then purchase the domain:</p>

        <mat-form-field>
            <mat-select [(ngModel)]="selectedGroup"
                        id="group_select">
                @for (g of groups; track g.name) {
                    <mat-option [value]="g.name">
                        {{g.name}}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <div class="button_row">
            <button mat-raised-button
                    color="warn"
                    (click)="purchaseDomain()">Purchase Domain!</button>

            <button mat-raised-button
                    color="primary"
                    (click)="cancelDomain()">Cancel</button>
        </div>
    </div>
}
