import {ChangeDetectionStrategy, Component, computed, HostListener, OnDestroy, OnInit} from '@angular/core';
import {finalize} from "rxjs/operators";
import {AuthService} from "../../services/auth.service";
import {StateService} from "../../services/state.service";
import {Router, RouterLink} from "@angular/router";
import {UtilService} from "../../services/util.service";
import {SessionService} from "../../services/session.service";
import {AlertService} from "../../services/alert.service";
import {BusyService} from "../../services/busy.service";
import {SwUpdate} from "@angular/service-worker";
import {NotificationService} from "../../services/notification.service";
import {CacheService} from "../../services/cache.service";
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {MatButton} from '@angular/material/button';
import {MatDialog} from "@angular/material/dialog";

interface BeforeInstallPromptEvent {
    prompt: () => {},
    userChoice?: Promise<any>
}

const AUTO_LOGOUT = "Your session was idle for too long,<br/> so you were automatically logged out"

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, MatButton, MatTooltip, MatIcon]
})
export class HeaderComponent implements OnInit, OnDestroy{

    promptEvent?: BeforeInstallPromptEvent;

    constructor(private authService: AuthService,
                private state: StateService,
                private cache: CacheService,
                private utilService: UtilService,
                private sessionService: SessionService,
                private alertService: AlertService,
                private busyService: BusyService,
                private swUpdateService: SwUpdate,
                private dialog: MatDialog,
                private notificationService: NotificationService,
                private router: Router) {
    }

    ngOnInit() {
        // Cover case of new app instance that happens to see remnants of an expired session created earlier in this browser on this or
        // a different tab, so we can clear all obsolete info and start fresh.
        if (this.sessionService.sessionHasExpired())
            this.logout();

        this.sessionService.sessionExpired().subscribe(() => {
            this.logout(AUTO_LOGOUT);
        });
        // If user is already logged in when header is loaded (typically a page reload), then start a session here, so the auto-logout
        // logic gets activated
        this.sessionService.restartSession();

        this.swUpdateService.versionUpdates.subscribe((ev) => {
            if (ev.type == 'VERSION_READY') {
                this.alertService.confirm("A new version of AS Domains is available.<br>Do you want to update to it?",
                    "Notification")
                    .subscribe((updateIt) => {
                        if (updateIt)
                            document.location.reload();
                    });
            }
        });
    }

    ngOnDestroy() {
    }

    @HostListener('window:beforeinstallprompt', ['$event'])
    handleBeforeInstallPrompt(event: BeforeInstallPromptEvent) {
        this.promptEvent = event;
    }

    async promptInstall() {
        if (this.promptEvent) {
            this.promptEvent.prompt();
            if (this.promptEvent.userChoice){
                const choice = await this.promptEvent.userChoice;
                if (choice?.outcome == "accepted") {
                    // Forget event, to cause Install button to disappear right away
                    this.promptEvent = undefined;
                }
            }
        }
    }

    get submitting(): boolean {
        return this.busyService.isBusy();
    }

    email = computed(() => this.state.get<string>('email')());

    isLoggedIn = computed(() => this.authService.isLoggedIn());

    logout(message?: string) {
        this.notificationService.close();
        this.cache.clearAll();
        this.dialog.closeAll();
        this.busyService.showBusy();
        this.authService.logOut().pipe(
            finalize(() => { this.busyService.showNotBusy() })
        ).subscribe({next: (info) => {
            this.state.clearAll();
            this.sessionService.clearSession();
            this.router.navigateByUrl('/login').then();
            if (message)
                this.alertService.warn(message);
        }, error: this.utilService.getErrorHandler("error logging out")
        });
    }

}
