
import {debounceTime, tap} from 'rxjs/operators';
import { AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { StringMap } from "./interfaces";
import { Constants } from "./constants";
import {Injectable} from "@angular/core";


/** Info required to initialize and validate a field in a reactive form */
export interface FieldInfo {
    value?: any;
    readonly validators?: ValidatorFn[];
    readonly asyncValidators?: AsyncValidatorFn[];
    readonly messages?: StringMap;
    errors?: string;
}

/** Info required to validate and report errors global to the whole form */
export interface FormInfo {
    readonly validator?: ValidatorFn;
    readonly asyncValidator?: AsyncValidatorFn;
    readonly messages?: StringMap;
    errors?: string;
}

/** Convenience interface: a map of strings to FieldInfo */
export interface FieldInfoMap {
    [name: string]: FieldInfo;
}

/** Encapsulates most of the logic required to validate a reactive form.

 *  A component that is or contains a form to be validated, should derive from this class. Alternatively, if no
 *  special validation customization is needed, it could instead just create an instance of this class and use it
 *  where needed.

 *  Regardless, the instance's initForm() method must be called with all the info describing the fields to be validated.
 *
 *  If special validation is required, as is the case when the values from two or more fields need to be compared,
 *  you must derive from this class and override the formValueChanged() method (and call the parent version as first
 *  thing).
 */
@Injectable()
export class ValidatedForm {
    /** The form group instance to interact with for validation purposes. Initialized in initForm() */
    form: UntypedFormGroup;
    /** Object with info about each field that needs validation */
    fields?: FieldInfoMap;
    /** Object with info required for global form validation */
    formInfo?: FormInfo;
    /** A flag that indicates whether the form has been properly initialized or not yet */
    private ready: boolean = false;
    /** A flag that indicates validation logic is waiting for a delay, and should be used to disable submit button */
    busy: boolean = false;
    /** Placeholder for flag to indicate that form submission has started, but not yet completed. Useful when deriving
     * from this class */
    submitting: boolean = false;
    /** How long to wait, in ms, before input values are accepted */
    debounceDelay: number = Constants.DEFAULT_DEBOUNCE_DELAY;

    /** Initializes the instance.
     *
     * @param fb {FormBuilder} required to create the FormGroup used to manage all the form controls, and which needs
     * to be passed to the <form> tag.
     */
    constructor(protected fb: UntypedFormBuilder) {
        this.form = this.fb.group({});
    }

    /** Initializes all the form validation logic using objects with fields, validation and error info
     *
     * @param info  object with info about each field that needs validation
     * @param formInfo object with info required for global form validation, if any
     */
    protected initForm(info: FieldInfoMap={}, formInfo: FormInfo={}) {
        // Remember field and form info
        this.fields = info;
        this.formInfo = formInfo;

        // Create form group init data with info for all the validated fields
        let controlsConfig: any = {};
        // Go through all form field names...
        Object.keys(this.fields).forEach((fieldName: string) => {
            // Get its full field info
            let field: FieldInfo = info[fieldName];
            // Always add to our init object (whether it has validators or not)
            controlsConfig[fieldName] = [field.value, field.validators, field.asyncValidators];
        });

        // Create form group with all the validated fields and extra form validation info
        this.form = this.fb.group(controlsConfig, formInfo);

        // React to form value changes
        this.form.valueChanges.pipe(
            // disable submission while waiting for debounce to complete
            tap(() => { this.busy = true }),
            // wait until this time has elapsed since last keystroke
            debounceTime(this.debounceDelay),
            // re-enable submission now debounce delay is finished
            tap(() => { this.busy = false })
        )// handle changed form field values
            .subscribe((data: any) => this.formValueChanged(data));

        // React to validation status changes, which is important when async validators are used
        this.form.statusChanges
            .subscribe((status: string) => this.formStatusChanged(status));

        this.ready = true;
    }

    isFormReady(): boolean {
        return this.ready;
    }

    /** Gets called when the value of one or more form fields has changed. It analyzes each of the fields and if
     * there are validation errors detected in it, it sets the errors attribute in the corresponding fields entry.
     * Finally, if the form is completely valid (no errors on any field), it updates the value attribute in all
     * the entries in the fields attribute.
     *
     * @param data  - object with values for each field name key
     */
    protected formValueChanged(data: any) {
        // Go through each validated field and set its errors attribute to contain HTML text with one line for
        // each error, if any, currently detected in its corresponding form control, obtaining the text from the
        // messages attribute.
        if (!this.fields)
            return;
        let fields: FieldInfoMap = this.fields;
        Object.keys(fields).forEach((fieldName: string) => {
            // Only deal with fields for which some data is received
            if (!data.hasOwnProperty(fieldName))
                return;

            // Get its full field info
            let field: FieldInfo = fields[fieldName];

            // Update its value from form data
            field.value = data[fieldName];

            // If it's a field that has validators, check for errors
            if (field.validators && !field.asyncValidators) {
                field.errors = '';
                let control = this.form?.get(fieldName);
                let messages: any = field.messages;
                if (control && control.dirty && !control.valid && !control.pending && messages) {
                    // Handle 'required' error specially: it will be the only error displayed
                    if (control.hasError('required'))
                        field.errors = messages['required'];
                    // Otherwise, concatenate all errors as multiple lines
                    else {
                        let errors: string[] = [];
                        Object.keys(messages).forEach((messageName: string) => {
                            if (messageName !== 'required' && control?.hasError(messageName))
                                errors.push(messages[messageName]);
                        });
                        field.errors = errors.join("<br/>");
                    }
                }
            }
        });
        // Now check if the form has a global (synchronous) validation error and extract it
        this.extractGlobalError();
    }

    /** Gets called when the validation status of one or more fields has changed. It checks only fields that
     * have async validators assigned and sets its errors attribute to match any errors detected.
     *
     * @param status
     */
    protected formStatusChanged(status: string) {
        if (status == "PENDING")
            return;
        let fields: any = this.fields as any;
        Object.keys(fields).forEach((fieldName: string) => {
            let field: FieldInfo = fields[fieldName];

            // Only check fields with async validators
            if (field && field.asyncValidators) {
                field.errors = '';
                let control = this.form?.get(fieldName);
                if (control && control.dirty && !control.valid && !control.pending) {
                    let errors: string[] = [];
                    let messages: any = fields.messages;
                    Object.keys(messages).forEach((messageName: string) => {
                        if (control?.errors && control.errors[messageName])
                            errors.push(messages[messageName]);
                    });
                    field.errors = errors.join("<br/>");
                }
            }
        });
        this.extractGlobalError();
    }

    /** If form has a global validator, check for any global errors, and set the global error to the
     * concatenation of any errors found (in multiple lines)
     */
    private extractGlobalError() {
        if (this.form && this.form.validator && this.formInfo) {
            this.formInfo.errors = '';
            if (this.form.dirty && !this.form.valid && !this.form.pending) {
                let errors: string[] = [];
                let messages: any = this.formInfo.messages;
                Object.keys(messages).forEach((messageName: string) => {
                    if (this.form?.hasError(messageName))
                        errors.push(messages[messageName]);
                });
                this.formInfo.errors = errors.join("<br/>");
            }
        }
    }
}
