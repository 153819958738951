import {ChangeDetectionStrategy, Component, signal} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DomainService, NameServerGroup} from "../../../services/domain.service";
import {BusyService} from "../../../services/busy.service";
import {finalize} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../../../services/alert.service";
import {StateService} from "../../../services/state.service";
import {MatOption} from '@angular/material/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {MatFormField} from '@angular/material/form-field';
import {MatButton} from '@angular/material/button';

@Component({
    templateUrl: './buy-domain.component.html',
    styleUrls: ['./buy-domain.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButton, MatFormField, MatSelect, ReactiveFormsModule, FormsModule, MatOption]
})
export class BuyDomainComponent {

    readonly domainName: string;
    readonly groups: NameServerGroup[] = [];
    available = signal<boolean|undefined>(undefined);
    selectedGroup = signal('dpns');

    constructor(private route: ActivatedRoute,
                private router: Router,
                private busyService: BusyService,
                private alertService: AlertService,
                private state: StateService,
                private domainService: DomainService) {
        this.domainName = this.route.snapshot.params['domain'];
        this.groups = this.state.get('groups')();
        if (!this.groups)
            this.cancelDomain();
    }

    cancelDomain() {
        this.router.navigateByUrl('/domains').then();
    }

    checkAvailability() {
        this.busyService.showBusy();
        this.domainService.isAvailable(this.domainName).pipe(
            finalize(() => {this.busyService.showNotBusy()})
        ).subscribe({
            next: (available) => {
                this.available.set(available);
                if (!available) {
                    this.alertService.infoObs(`Domain <b>${this.domainName}</b> is <b>not</b> available for purchase`).subscribe(() => {
                        this.cancelDomain();
                    });
                }
            },
            error: (err: HttpErrorResponse) => {
                this.alertService.error("Error checking domain availability: " + err.error || err.message);
            }
        });
    }

    purchaseDomain() {
        this.alertService.confirm( `Are you sure you want to purchase the domain ${this.domainName}?<br/>This cannot be undone.`)
            .subscribe((yesDoIt) => {
                if (yesDoIt) {
                    this.busyService.showBusy();
                    this.domainService.create(this.domainName, this.selectedGroup()).pipe(
                        finalize(() => {this.busyService.showNotBusy()})
                    ).subscribe({
                        next: (out) => {
                            this.alertService.infoObs(`Domain ${this.domainName} purchased successfully`).subscribe(() => {
                                this.cancelDomain();
                            });
                        },
                        error: (err: HttpErrorResponse) => {
                            this.alertService.error("Error purchasing domain: " + err.error || err.message);
                        }
                    });
                }
            });
    }
}
