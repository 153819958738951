import {ChangeDetectionStrategy, Component, ElementRef, viewChild} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {BulkResponse, DomainService} from "../../services/domain.service";
import {BusyService} from "../../services/busy.service";
import {finalize} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../../services/alert.service";
import {Observable} from "rxjs";

@Component({
    standalone: true,
    imports: [
        MatButton
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './bulk.component.html',
    styleUrl: './bulk.component.scss'
})
export class BulkComponent {

    setPickUpload = viewChild<ElementRef>('setPickUpload');
    clearPickUpload = viewChild<ElementRef>('clearPickUpload');

    constructor(private domainService: DomainService,
                private alertService: AlertService,
                private busyService: BusyService) {
    }

    setPickLoaded(ev: Event): void {
        this.changePickLoaded(ev, true);
    }

    clearPickLoaded(ev: Event): void {
        this.changePickLoaded(ev, false);
    }

    private changePickLoaded(ev: Event, flag: boolean): void {
        const elem = ev.target as HTMLInputElement;
        if (elem.files && elem.files[0]) {
            const file = elem.files[0];
            console.log("file:", file.name);
            let reader = new FileReader();
            reader.addEventListener("load", (e) => {
                const domains = this.preprocessList(reader.result as string);
                this.changePicks(domains, flag);
                this.uploadReset(flag? this.setPickUpload()! : this.clearPickUpload()!);
            }, false);
            reader.readAsText(file);
        }
    }

    private uploadReset(fileInput: ElementRef): void {
        // Must also allow the file input element to forget its prior file, in case user wants to upload the same
        // picture again. Otherwise, the change event doesn't trigger because nothing has changed. This seems to be
        // the cleanest way to achieve it.
        fileInput.nativeElement.type = "";
        fileInput.nativeElement.type = "file";
    }

    private preprocessList(body: string): string[] {
        // Get one domain from each line, as lowercase, removing leading or trailing spaces, and skipping empty lines
        let domains = body.split('\n')
            .map((line) => line.toLocaleLowerCase().trim())
            .filter(line => line.length > 0);
        // Now remove duplicates and sort it
        return [...new Set(domains)].sort();
    }

    private changePicks(domains: string[], flag: boolean): void {
        console.log(`Uploading ${domains.length} domains`);
        this.busyService.showBusy();
        const obs: Observable<BulkResponse> = flag? this.domainService.bulkSetPicks(domains)
                                                  : this.domainService.bulkClearPicks(domains);
        obs.pipe(
            finalize(() => this.busyService.showNotBusy())
        ).subscribe({
            next: (out: BulkResponse) => {
                let msg = `Updated domains: ${out.changed}`;
                if (out.missing.length > 0) {
                    msg += "<br/><p>The following domains were not found:</p>" + out.missing.join("<br/>");
                }
                this.alertService.info(msg);
            },
            error: (err: HttpErrorResponse) => {
                this.alertService.error("Error changing domains as picks: " + err.error?.message || err.error || err.message);
            }
        })
    }
}
