import {Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {DOCUMENT} from '@angular/common';

/** Provides information about the current URL that works well in Angular Universal also. It also provides
 * immutable info about the current site's root and other URLs.
 */
@Injectable({
    providedIn: 'root'
})
export class PathService {
    private static DEV_API_ROOT = "http://localhost:8080/api";
    private static DEV_HOST = "localhost:8080";
    private static HOME_DOMAIN = ".maprilis.net";

    /** The host name used to access the current page */
    private readonly host: string;
    private readonly origin:string;


    /** Initializes service
     *
     * @param document
     * @param injector
     * @param platformId
     */
    constructor(@Inject(DOCUMENT) private document: Document,
                private injector: Injector,
                @Inject(PLATFORM_ID) private platformId: Object) {
        this.host = this.document.location.host;
        this.origin = this.document.location.origin;
    }

    /** Returns the absolute URL for the root of our own API URLs
     *
     * @returns {string} the URL
     */
    get apiRoot(): string {
        if (this.origin.includes(PathService.HOME_DOMAIN))
            return `${this.origin}/api`;
        else
            return PathService.DEV_API_ROOT;
    }

    getNotifUrl(token: string): string {
        if (this.origin.includes(PathService.HOME_DOMAIN))
            return `wss://${this.host}/notifications/${token}`;
        else
            return `ws://${PathService.DEV_HOST}/notifications/${token}`;
    }

}
