import {signal, WritableSignal} from "@angular/core";

export class WritableSignalMap<T=any> {

    private map = new Map<string,WritableSignal<T|undefined>>;

    get(name: string): WritableSignal<T|undefined> {
        if (!this.map.has(name))
            this.map.set(name, signal<T|undefined>(undefined));
        return this.map.get(name)!;
    }

    set(name: string, value: T|undefined) {
        if (!this.map.has(name))
            this.map.set(name, signal<T|undefined>(value));
        else
            this.map.get(name)!.set(value);
    }

    delete(name: string): boolean {
        // First set the signal to undefined (to let any watchers be notified of their disappearance)
        if (this.map.has(name))
            this.map.get(name)!.set(undefined);
        // And then remove it from the map
        return this.map.delete(name);
    }

    clear() {
        // First set all signal values to undefined, so any changes dependent on them can be triggered.
        this.map.forEach((sig) => {
            sig.set(undefined);
        });

        // And then remove all signals from the map
        this.map.clear();
    }

}