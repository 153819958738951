import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PathService} from "./path.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OkInfo} from "../util/interfaces";

export interface NameServerGroup {
    name: string;
    hosts: string[]
}

export interface DomainInfo {
    name: string;
    in_registry: boolean;
    in_inquiry: boolean;
    pick: boolean;
    registered: number;
    updated: number;
    expires: number;
    transferred_out: number|null;
    locked: boolean;
    ns_group: NameServerGroup;
}

interface Matches {
    matches: string[];
}

interface NameServerGroupResponse {
    groups: NameServerGroup[];
}

interface CheckResponse {
    available: boolean;
}

export interface BulkResponse {
    changed: number;
    missing: string[];
}

export interface EppBaseDomainInfo {
    name: string;
    in_registry: boolean;
    valid: boolean;
}

export interface EppFullDomainInfo extends EppBaseDomainInfo {
    ns_hosts: string[];
    statuses: string[];
    created: number;
    updated: number;
    expires: number;
    auth_code: string;
}

export type EppDomainInfo = EppBaseDomainInfo | EppFullDomainInfo;


@Injectable({
    providedIn: 'root'
})
export class DomainService {

    private readonly domainRoot: string;

    constructor(private http: HttpClient,
                private pathService: PathService) {
        this.domainRoot = `${this.pathService.apiRoot}/domain`;
    }

    getInfo(domainName: string): Observable<DomainInfo> {
        return this.http.get<DomainInfo>(`${this.domainRoot}/info/${domainName}`);
    }

    find(domainPartial: string): Observable<string[]> {
        return this.http.get<Matches>(`${this.domainRoot}/find/${domainPartial}`).pipe(
            map((out) => out.matches || [])
        );
    }

    markInRegistry(domainName: string, inRegistry: boolean): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.domainRoot}/in-registry/${domainName}`, {inRegistry: inRegistry});
    }

    markInInquiry(domainName: string, inInquiry: boolean): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.domainRoot}/in-inquiry/${domainName}`, {inInquiry: inInquiry});
    }

    bulkSetPicks(domains: string[]): Observable<BulkResponse> {
        return this.http.put<BulkResponse>(`${this.domainRoot}/bulk/set-picks`, {domains: domains});
    }

    bulkClearPicks(domains: string[]): Observable<BulkResponse> {
        return this.http.put<BulkResponse>(`${this.domainRoot}/bulk/clear-picks`, {domains: domains});
    }

    getNameServerGroups(): Observable<NameServerGroup[]> {
        return this.http.get<NameServerGroupResponse>(`${this.domainRoot}/nsgroups`).pipe(
            map((out) => out.groups)
        );
    }

    eppGetInfo(domainName: string): Observable<EppDomainInfo> {
        return this.http.get<EppDomainInfo>(`${this.domainRoot}/epp/info/${domainName}`);
    }

    create(domainName: string, nsGroupName: string): Observable<OkInfo> {
        return this.http.post<OkInfo>(`${this.domainRoot}/epp/create`, {
            domainName: domainName,
            nsGroupName: nsGroupName
        });
    }

    delete(domainName: string): Observable<OkInfo> {
        return this.http.delete<OkInfo>(`${this.domainRoot}/${domainName}`);
    }

    lock(domainName: string): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.domainRoot}/epp/lock/${domainName}`, {});
    }

    unlock(domainName: string): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.domainRoot}/epp/unlock/${domainName}`, {});
    }

    syncDomainDates(domainName: string): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.domainRoot}/sync/${domainName}`, {});
    }

    changeNameServerGroup(domainName: string, groupName: string): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.domainRoot}/${domainName}/nsgroup/${groupName}`, {});
    }

    approveTransfer(domainName: string): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.domainRoot}/${domainName}/transfer`, {});
    }

    isAvailable(domainName: string): Observable<boolean> {
        return this.http.get<CheckResponse>(`${this.domainRoot}/epp/check/${domainName}`).pipe(
            map((out) => out.available)
        );
    }
}
