import {Injectable} from "@angular/core";
import {StateService} from "../services/state.service";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import {PathService} from "../services/path.service";
import {AuthService} from "../services/auth.service";
import {from, Observable, timer} from "rxjs";
import {mergeMap, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {AlertService} from "../services/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {SessionService} from "../services/session.service";

const MSG_EXPIRED_SESSION = "Session has expired";

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(private state: StateService,
                private pathService: PathService,
                private router: Router,
                private dialogRef: MatDialog,
                private alertService: AlertService,
                private sessionService: SessionService,
                private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // If the request was for an API url and the response has a 401 status code, then clean up and redirect
        if (req.url.startsWith(`${this.pathService.apiRoot}/`)) {
            return next.handle(req).pipe(tap((ev: HttpEvent<any>) => {
                if (ev instanceof HttpResponse) {
                    // do something with a valid response, if needed here
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse && err.status == 401) {
                    // If user is logged in and call with expiration is not the actual logout call, then warn the user and
                    // execute a logout with cleanup
                    if (this.authService.isLoggedIn() && req.url != `${this.pathService.apiRoot}/auth/logout`) {
                        from(this.router.navigateByUrl('/login')).subscribe((out) => {
                            this.state.clearAll();
                            this.dialogRef.closeAll();
                            this.sessionService.clearSession();
                            timer(100).subscribe(() => {
                                this.alertService.warn(MSG_EXPIRED_SESSION);
                            });
                        }, (err) => {
                            console.error("Error logging out:", err);
                        });
                    }
                }
            }));
        } else {
            return next.handle(req);
        }
    }
}
