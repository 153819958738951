import {Injectable} from '@angular/core';
import {Domain} from "./for-sale.service";
import {HttpClient} from "@angular/common/http";
import {PathService} from "./path.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OkInfo} from "../util/interfaces";

interface DomainList {
    domains: Domain[];
}

export interface DomainAddInfo {
    regByAprilSea: boolean;
    usesPrimaryDns: boolean;
    removedFromZone: string|null;
    registrar: string;
}

@Injectable({
    providedIn: 'root'
})
export class DnsZoneService {

    private readonly zoneRoot: string;

    constructor(private http: HttpClient,
                private pathService: PathService) {
        this.zoneRoot = `${this.pathService.apiRoot}/zone`;
    }

    getAllDomains(zone: string): Observable<Domain[]> {
        return this.http.get<DomainList>(`${this.zoneRoot}/${zone}`).pipe(
            map(((out) => out.domains || []))
        );
    }

    addDomain(zone: string, domain: string): Observable<DomainAddInfo> {
        return this.http.post<DomainAddInfo>(`${this.zoneRoot}/${zone}`, {domain: domain});
    }

    removeDomain(zone: string, domain: string): Observable<OkInfo> {
        return this.http.delete<OkInfo>(`${this.zoneRoot}/${zone}/${domain}`)
    }
}
