import {ChangeDetectionStrategy, Component, computed, OnInit, Signal} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ReactiveFormsModule, UntypedFormBuilder, Validators} from "@angular/forms";
import {finalize, map} from "rxjs/operators";
import {ValidatedForm} from "../../util/validated-form";
import {AlertService} from "../../services/alert.service";
import {StateService} from "../../services/state.service";
import {UtilService} from "../../services/util.service";
import {AuthService} from "../../services/auth.service";
import {Constants} from "../../util/constants";
import {AppValidators} from "../../util/app-validators";
import {Passwords} from "../../util/passwords";
import {BusyService} from "../../services/busy.service";
import {SessionService} from "../../services/session.service";
import {MatButton} from '@angular/material/button';
import {PasswordInputComponent} from '../../controls/password-input/password-input.component';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField} from '@angular/material/form-field';
import {toSignal} from "@angular/core/rxjs-interop";
import {FormSignals} from "../../util/form-signals";

const MSG_PASSWORD_UPDATED = "The password was updated correctly";

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, MatFormField, MatInput, MatError, PasswordInputComponent, MatButton]
})
export class ResetPasswordComponent extends ValidatedForm implements OnInit {

    isBusy = computed(() => this.busyService.isBusy());
    formSignals: FormSignals;

    readonly passwordHint: string;
    private resetToken?: string;

    constructor(protected override fb: UntypedFormBuilder,
                private route: ActivatedRoute,
                private alertService: AlertService,
                private state: StateService,
                private sessionService: SessionService,
                private utilService: UtilService,
                private busyService: BusyService,
                private authService: AuthService) {
        super(fb);
        this.initForm({
            email: {
                value: '',
                validators: [Validators.pattern(Constants.EMAIL_REGEX), Validators.required],
                messages: {pattern: Constants.ERR_INVALID_EMAIL, required: Constants.ERR_REQUIRED}
            },
            password: {
                value: '',
                validators: [Validators.required, AppValidators.PasswordQualityValidator(Passwords.DEFAULT_OPTIONS)],
                messages: {
                    required: Constants.ERR_REQUIRED,
                    minLength: Constants.ERR_PASSWORD_LENGTH,
                    letters: Constants.ERR_PASSWORD_LETTERS_DIGITS,
                    digits: Constants.ERR_PASSWORD_LETTERS_DIGITS,
                    allowSpaces: Constants.ERR_PASSWORD_SPACES
                }
            },
            password2: {
                value: '',
                validators: [Validators.required],
                messages: {required: Constants.ERR_REQUIRED}
            }
        }, {
            validator: AppValidators.ControlMatchValidator('password', 'password2'),
            messages: {mismatch: Constants.ERR_PASSWORD_MISMATCH}
        });
        this.formSignals = new FormSignals(this.form, {
            email: this.fields!['email'].messages!,
            password: this.fields!['password'].messages!,
            password2: this.fields!['password2'].messages!
        }, this.formInfo!.messages);

        this.passwordHint = Passwords.GetHint();
    }

    ngOnInit() {
        this.resetToken = this.route.snapshot.params['token'];
        if (this.authService.isLoggedIn()){
            this.busyService.showBusy();
            this.authService.logOut().pipe(
                finalize(() => {this.busyService.showNotBusy()})
            ).subscribe({
                next: () => {
                    this.state.clearAll();
                    this.sessionService.clearSession();
                },
                error: this.utilService.getErrorHandler("Error in auto logout")
            });
        }
    }

    submit() {
        const email = this.fields? this.fields['email'].value : '';
        const resetToken = this.resetToken || '';
        const password = this.fields? this.fields['password'].value : '';
        this.busyService.showBusy();
        this.authService.resetPassword(email, resetToken, password).pipe(
            finalize(() => {
                this.busyService.showNotBusy();
            })
        ).subscribe({
                next: () => {
                    this.alertService.infoObs(MSG_PASSWORD_UPDATED)
                        .subscribe(() => {
                            this.state.clearAll();
                            this.authService.redirectToLogIn();
                        });
                },
                error: this.utilService.getErrorHandler("Error resetting password")
            }
        );
    }
}
