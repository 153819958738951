<span class="logo"
      routerLink="/domains">April Sea Domains</span>

@if (promptEvent) {
    <button mat-raised-button color="accent" (click)="promptInstall()" matTooltip="Install as an app to your desktop">
        <mat-icon>install_desktop</mat-icon>
        Install
    </button>
}
@if (isLoggedIn()) {
    <span id="account">
        <button mat-raised-button
                color="primary"
                [disabled]="submitting"
                (click)="logout()">Log Out</button>
         <span id="email">{{email()}}</span>
    </span>
}
