import {Injectable} from '@angular/core';
import {StateService} from "./state.service";
import {PathService} from "./path.service";
import {ReplaySubject} from "rxjs";

/** Takes care of receiving push notifications from the server
 */
@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private socket?: WebSocket;
    private messageSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
    readonly message$ = this.messageSubject.asObservable();


    constructor(private state: StateService, private pathService: PathService) {
    }

    connect() {
        if (this.socket)
            return;
        console.log("NotificationService - opening websocket connection");
        this.socket = new WebSocket(this.pathService.getNotifUrl(this.state.get('token')()));
        this.socket.addEventListener('open', () => {
            console.log("web socket connection open!")
        });
        this.socket.addEventListener('message', (event) => {
            console.log("web socket got message:", event.data);
            this.messageSubject.next(event.data);
        });
        this.socket.addEventListener('close', (ev: CloseEvent) => {
            console.log("web socket connection closed - ev.code:", ev.code);
            // If event code is 1008 (policy violation, it was a forced closing by the server because of auth token
            // validation failed, so just give up and leave it closed. Otherwise, (typically code 1006) reopen the
            // web socket. Also don't reconnect if we've closed the connection ourselves with a call to close() below
            // which sets the socket to undefined.
            const shouldReconnect: boolean = ev.code != 1008 && !!this.socket;
            this.socket = undefined;
            if (shouldReconnect)
                this.connect();
        });
        console.log("Client websocket initialized");
    }

    sendMessage(msg: string) {
        this.socket?.send(msg);
    }

    close() {
        this.socket?.close();
        this.socket = undefined;
    }

}
