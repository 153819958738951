import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    input,
    OnInit,
    Signal,
    signal, viewChild
} from '@angular/core';
import {ControlContainer, FormGroupDirective, ReactiveFormsModule} from '@angular/forms';
import {timer} from 'rxjs';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField, MatHint, MatLabel, MatSuffix} from '@angular/material/form-field';

@Component({
    selector: 'aa-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatFormField, MatLabel, MatInput, ReactiveFormsModule, MatIcon, MatSuffix, MatHint, MatError]
})
export class PasswordInputComponent implements OnInit {

    input = viewChild<ElementRef>('input');

    label = input.required();
    placeholder = input('');
    required = input(false);
    autofocus = input(false);
    controlName = input('', {alias: 'control_name'});
    hint = input<string>();
    errors = input<string>();

    visible = signal(false);

    constructor() {
    }

    ngOnInit(): void {
    }

    focus() {
        timer(100).subscribe(() => {
            this.input()?.nativeElement.focus()
        });
    }

    toggleVisibility() {
        this.visible.set(!this.visible());
    }

    getType: Signal<string> = computed(() => this.visible()? 'text' : 'password');

    getIcon: Signal<string> = computed(() => this.visible()? 'visibility_off' : 'visibility');

    getTooltip: Signal<string> = computed(() => this.visible()? 'Hide password' : 'Show password');
}
