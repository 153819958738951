import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from "../../services/notification.service";
import {CacheService} from "../../services/cache.service";
import {Subscription} from "rxjs";
import {CacheMessageMapper} from "../../util/cache-message-mapper";
import {StateService} from "../../services/state.service";

/** Will clear cached tags whenever a push notification message is received, regardless of what page is being
 * displayed on screen.
 */
@Component({
    selector: 'app-cache-clearer',
    template: '',
    standalone: true
})
export class CacheClearerComponent implements OnInit, OnDestroy{

    private notifSub?: Subscription;

    constructor(private notificationService: NotificationService,
                private cache: CacheService,
                private state: StateService) {
    }

    ngOnInit() {
        const simplePats = [
            CacheMessageMapper.TRANSACTIONS_PAT,
            CacheMessageMapper.DOMAINS_PAT,
            CacheMessageMapper.DNS_ZONE_PAT,
            CacheMessageMapper.USERS_PAT
        ];
        this.notifSub = this.notificationService.message$.subscribe((message) => {
            for (let pat of simplePats) {
                const info = CacheMessageMapper.map(message, pat);
                if (info) {
                    console.log("CacheClearer - clearing cached tags for message: " + info.tags.join(', '));
                    this.cache.clear(...info.tags);
                    return;
                }
            }
            // Special case for USERS pattern, which only should clear settings if about current user
            const info = CacheMessageMapper.map(message, CacheMessageMapper.SETTINGS_PAT);
            if (info) {
                const userId: string = this.state.get('userId')().toString();
                if (info.label == userId) {
                    console.log("CacheClearer - clearing cached tags for message: " + info.tags.join(', '));
                    this.cache.clear(...info.tags);
                }
            }
        });
    }

    ngOnDestroy() {
        this.notifSub?.unsubscribe();
    }
}
