@if (!isBusy() && isAdmin) {
    <div class="button_row">
        <mat-form-field id="name_field">
            <div id="input_row">
                <input matInput
                       placeholder="Domain name or partial name"
                       [(ngModel)]="newDomain"
                       [matAutocomplete]="auto"
                       (input)="handleDomainInputChange()"
                       (keydown.escape)="clearSearch()"
                       (keydown.enter)="maybeAddDomain()">
                @if (searching()) {
                    <mat-spinner id="domain_spinner"
                                 [diameter]="16"></mat-spinner>
                }
                @if (newDomain()) {
                    <button class="close_btn"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                }
            </div>
            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
                @for (match of filteredMatches(); track $index) {
                    <mat-option [value]="match">
                        {{match}}
                    </mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
        <button mat-raised-button color="primary"
                [disabled]="!hasDomain() || isBusy()"
                (click)="addDomain()">
            + Add Domain
        </button>
    </div>
}
<div [ngClass]="{hidden: isBusy() || !hasDomains()}" class="paginator_row">
    <mat-form-field id="filter_field">
        <mat-label>Table Filter</mat-label>
        <input matInput
               placeholder="Partial domain name"
               [(ngModel)]="domainFilter"
               (input)="filterChanged()"
               (keydown.escape)="filterCleared()">
        @if (domainFilter()) {
            <button class="close_btn"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="filterCleared()">
                <mat-icon>close</mat-icon>
            </button>
        }
    </mat-form-field>
    <mat-paginator #paginator
                   [length]="domains().length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[10, 20, 40]"></mat-paginator>
</div>
<div id="table_scroller">
    @if (!isBusy() && hasDomains()) {
        <table mat-table [dataSource]="dataSource()">
            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef>Domain name</th>
                <td mat-cell *matCellDef="let domain" [innerHTML]="maybeLink(domain)"></td>
            </ng-container>

            <ng-container matColumnDef="inRegistry">
                <th mat-header-cell *matHeaderCellDef>In registry</th>
                <td mat-cell *matCellDef="let domain" [innerHTML]="domain.inRegistry? '&check;' : ''"></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let domain">
                    <button mat-icon-button
                            color="primary"
                            title="Delete"
                            [disabled]="!isAdmin"
                            (click)="removeDomain(domain.name)"><mat-icon>delete</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row
                *matRowDef="let domain; columns: columnsToDisplay"></tr>
        </table>
    }
</div>
