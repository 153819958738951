<div class="title">
    Edit Device
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field>
        <input matInput
               formControlName="alias"
               placeholder="Device alias">
        <mat-error>{{formSignals.errors('alias')()}}</mat-error>
    </mat-form-field>

    <div class="actions">
        <button mat-raised-button
                color="primary"
                [disabled]="!canSubmit()"
                type="submit"
                mat-dialog-close="">Submit</button>
        <button mat-raised-button
                type="button"
                mat-dialog-close="">Cancel</button>
    </div>
</form>
