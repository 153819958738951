<mat-form-field>
    @if (label()) {
        <mat-label>{{label()}}</mat-label>
    }
    <input matInput
           #input
           [required]="required()"
           [autofocus]="autofocus()"
           [type]="getType()"
           [placeholder]="placeholder()"
           [formControlName]="controlName()">
    <mat-icon matSuffix
              (click)="toggleVisibility()"
              [title]="getTooltip()">{{getIcon()}}</mat-icon>
    @if (hint()) {
        <mat-hint>{{hint()}}</mat-hint>
    }
    <mat-error>{{errors()}}</mat-error>
</mat-form-field>
