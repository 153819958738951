<h1>Forgot Password</h1>
<div id="info">Enter your email and we will send you a link to change your password</div>
<form [formGroup]="form" (ngSubmit)="submit()">
  <div>
    <mat-form-field>
      <input matInput
             required
             placeholder="Email"
             formControlName="email">
      <mat-error>{{emailErrors()}}</mat-error>
    </mat-form-field>
  </div>
  <div id="button_row">
    <button mat-raised-button
            class="button"
            type="submit"
            color="primary"
            [disabled]="!formSignals.formValid() || isBusy()">Send Link</button>
    <button mat-raised-button
            class="button"
            type="button"
            [disabled]="isBusy()"
            routerLink="/login">Cancel</button>
  </div>
</form>
