import {ChangeDetectionStrategy, Component, computed, OnInit, Signal} from '@angular/core';
import {ValidatedForm} from "../../util/validated-form";
import {ReactiveFormsModule, UntypedFormBuilder, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";
import {UtilService} from "../../services/util.service";
import {Constants} from "../../util/constants";
import {finalize} from "rxjs/operators";
import {BusyService} from "../../services/busy.service";
import {StateService} from "../../services/state.service";
import {SessionService} from "../../services/session.service";
import {RouterLink} from '@angular/router';
import {MatButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField} from '@angular/material/form-field';
import {FormSignals} from "../../util/form-signals";

const RESET_LINK_SENT_MSG = "If your email is registered, you'll soon receive a link<br/> to change you password.";

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, MatFormField, MatInput, MatError, MatButton, RouterLink]
})
export class ForgotPasswordComponent extends ValidatedForm implements OnInit {

    isBusy = computed(() => this.busyService.isBusy());
    formSignals: FormSignals;
    emailErrors: Signal<string>;

    constructor(protected override fb:UntypedFormBuilder,
                private authService: AuthService,
                private alertService: AlertService,
                private busyService: BusyService,
                private state: StateService,
                private sessionService: SessionService,
                private utilService: UtilService) {
        super(fb);
        this.initForm({
            email: {
                value: '',
                validators: [Validators.pattern(Constants.EMAIL_REGEX), Validators.required],
                messages: {pattern: Constants.ERR_INVALID_EMAIL, required: Constants.ERR_REQUIRED}
            }
        });
        this.formSignals = new FormSignals(this.form, {
            email: this.fields!['email'].messages!
        });
        this.emailErrors = computed(() => this.formSignals.errors('email')());
    }

    ngOnInit() {
        if (this.authService.isLoggedIn()){
            this.busyService.showBusy();
            this.authService.logOut().pipe(
                finalize(() => {this.busyService.showNotBusy()})
            ).subscribe({
                next: () => {
                    this.state.clearAll();
                    this.sessionService.clearSession();
                },
                error: this.utilService.getErrorHandler("Error in auto logout")
            });
        }
    }

    submit() {
        if (this.fields) {
            this.busyService.showBusy();
            this.authService.requestPasswordReset(this.fields['email'].value).pipe(
                finalize(() => { this.busyService.showNotBusy() })
            ).subscribe({
                next: () => {
                    this.alertService.info(RESET_LINK_SENT_MSG);
                },
                error: this.utilService.getErrorHandler("Error requesting password reset")
            });
        }
    }
}
