import {ChangeDetectionStrategy, Component, computed, Inject} from '@angular/core';
import {ValidatedForm} from "../../util/validated-form";
import {ReactiveFormsModule, UntypedFormBuilder, Validators} from "@angular/forms";
import {BusyService} from "../../services/busy.service";
import {AlertService} from "../../services/alert.service";
import {MAT_DIALOG_DATA, MatDialogClose} from "@angular/material/dialog";
import {Constants} from "../../util/constants";
import {AuthService, TrustedDevice} from "../../services/auth.service";
import {finalize} from "rxjs/operators";
import {MatButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField} from '@angular/material/form-field';
import {FormSignals} from "../../util/form-signals";

@Component({
    templateUrl: './device-dialog.component.html',
    styleUrls: ['./device-dialog.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, MatFormField, MatInput, MatError, MatButton, MatDialogClose]
})
export class DeviceDialogComponent extends ValidatedForm {

    formSignals: FormSignals;

    constructor(protected override fb: UntypedFormBuilder,
                private authService: AuthService,
                private busyService: BusyService,
                private alertService: AlertService,
                @Inject(MAT_DIALOG_DATA) private device: TrustedDevice) {
        super(fb);
        this.initForm({
            alias: {
                value: this.device.alias,
                validators: [Validators.required],
                messages: {'required': Constants.ERR_REQUIRED}
            }
        });
        this.formSignals = new FormSignals(this.form,
            {alias: this.fields!['alias'].messages!}
        );
    }

    canSubmit =computed(() => {
        // We can submit if the form is valid and the alias name has changed
        return this.formSignals.formValid() && this.formSignals.value('alias')() != this.device.alias;
    });

    submit() {
        this.busyService.showBusy();
        const fields: any = this.fields;
        this.device.alias = fields['alias'].value;
        this.authService.updateTrustedDevice(this.device).pipe(
            finalize(() => {this.busyService.showNotBusy()})
        ).subscribe({
            next: () => {
            },
            error: (err) => {
                this.alertService.error("Error updating device: " + err.message);
                console.error("Error updating device:", err);
            }
        });
    }
}
