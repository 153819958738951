import {Injectable, WritableSignal} from '@angular/core';
import {AnyMap} from '../util/interfaces';
import {WritableSignalMap} from "../util/writable-signal-map";

const APP_KEY = "dom-state";

/** Persistent state storage of any kind of value associated to any string key
 */
@Injectable({
    providedIn: 'root'
})
export class StateService {

    private state: AnyMap = {};
    private signalState = new WritableSignalMap();

    constructor() {
        this.hydrate();
    }

    get<T=any>(key: string): WritableSignal<T|undefined> {
        return this.signalState.get(key);
    }

    set<T=any>(key: string, value: T) {
        this.state[key] = value;
        this.signalState.set(key, value);
        this.persist();
    }

    clear(...keys: string[]) {
        keys.forEach((key) => {
            delete this.state[key];
            this.signalState.delete(key);
        });
        this.persist();
    }

    clearAll() {
        this.state = {};
        this.signalState.clear();
        localStorage.removeItem(APP_KEY);
    }

    setMany(map: AnyMap) {
        Object.keys(map).forEach((key:string) => {
            this.state[key] = map[key];
            this.signalState.set(key, map[key]);
        });
        this.persist();
    }

    private persist() {
        localStorage.setItem(APP_KEY, JSON.stringify(this.state))
    }

    private hydrate() {
        const saved = localStorage.getItem(APP_KEY);
        if (saved) {
            this.state = JSON.parse(saved);
            Object.keys(this.state).forEach((key) => {
                this.signalState.set(key, this.state[key]);
            });
        }
    }
}
