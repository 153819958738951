import {Injectable} from '@angular/core';
import {PathService} from "./path.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {User} from "./auth.service";

export interface DomainAction {
    id: number;
    domainName: string;
    completed: number;
    result: string;
    data?: string;
    dataId?: number;
}

export interface Transaction {
    transId: number;
    userId: number;
    operation: string;
    domainCount: number;
    startDate: number;
    completedDate: number;
    notes: string;
    actions: DomainAction[];
}

interface TransactionListWrapper {
    transactions: Transaction[];
}

interface UserListWrapper {
    users: User[];
}

@Injectable({
    providedIn: 'root'
})
export class TransactionService {

    private readonly transRoot: string;

    constructor(private http: HttpClient,
                private pathService: PathService) {
        this.transRoot = `${this.pathService.apiRoot}/trans`;
    }

    search(startDate: string, endDate: string, userId?: number, operationId?: number): Observable<Transaction[]> {
        let url: string = `${this.transRoot}/search?startDate=${startDate}&endDate=${endDate}`;
        if (userId)
            url += `&userId=${userId}`;
        if (operationId)
            url += `&operationId=${operationId}`;
        return this.http.get<any>(url).pipe(
            map((out) => out.transactions || [])
        );
    }

    /** Retrieves all the latest transactions of any type and by any user up to the given maximum count, sorted by
     * decreasing timestamp
     *
     * @param maxTransactions the maximum number of transactions to retrieve
     * @return Observable of an array of Transaction instances
     */
    getAll(maxTransactions: number): Observable<Transaction[]> {
        return this.http.get<TransactionListWrapper>(`${this.transRoot}?maxTransactions=${maxTransactions}`).pipe(
            map((out) => out.transactions || [])
        );
    }

    domainTransactions(domain: string): Observable<Transaction[]> {
        return this.http.get<TransactionListWrapper>(`${this.transRoot}/domain-trans?domain=${domain}`).pipe(
            map((out) => out.transactions || [])
        );
    }

    getAllUsers(): Observable<User[]> {
        return this.http.get<UserListWrapper>(`${this.transRoot}/users`).pipe(
            map((out) => out.users)
        );
    }

}
