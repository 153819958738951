import {ChangeDetectionStrategy, Component, computed, input, OnDestroy, signal} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-nav-link',
    templateUrl: './nav-link.component.html',
    styleUrls: ['./nav-link.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink]
})
export class NavLinkComponent implements OnDestroy {

    text = input('');
    url = input<string>();
    activePattern = input<RegExp|undefined>(undefined, {alias: 'active_pattern'});

    isActive = computed(() => !!this.activePattern()?.test(this.currentUrl()));

    private currentUrl =  signal('');
    private routerSub?: Subscription;

    constructor(private router: Router) {
        this.currentUrl.set(this.router.routerState.snapshot.url);
        this.routerSub = this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd)
                this.currentUrl.set(ev.url);
        });
    }

    ngOnDestroy() {
        this.routerSub?.unsubscribe();
    }
}
