import {ChangeDetectionStrategy, Component, signal} from '@angular/core';
import {DnsZoneComponent} from '../../controls/dns-zone/dns-zone.component';
import {NgClass} from '@angular/common';

@Component({
    templateUrl: './dns-zones.component.html',
    styleUrls: ['./dns-zones.component.scss'],
    standalone: true,
    imports: [NgClass, DnsZoneComponent],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DnsZonesComponent {
    currentZone = signal("forsale");

    select(zone: string) {
        this.currentZone.set(zone);
    }
}
