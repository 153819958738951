import {Component, effect} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BusyOverlayComponent} from "../busy-overlay/busy-overlay.component";
import {BusyService} from "../../services/busy.service";

@Component({
    selector: 'app-busy-launcher',
    template: '',
    standalone: true
})
export class BusyLauncherComponent {

    private dialogRef?: MatDialogRef<BusyOverlayComponent>;

    constructor(private busyService: BusyService,
                private dialog: MatDialog) {
        effect(() => {
            if (this.busyService.isBusy()) {
                this.dialogRef = this.dialog.open(BusyOverlayComponent,
                    {width: '150px', height: '150px', disableClose: true});
            } else if (this.dialogRef) {
                this.dialogRef.close();
                this.dialogRef = undefined;
            }
        });
    }
}
