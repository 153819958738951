<h1>Admin</h1>

<h2>Users</h2>

@if (!isBusy() && hasUsers()) {
    <div id="button_row">
        <button mat-raised-button color="primary"
                (click)="addUser()">+ Add User</button>
    </div>
    <div id="table_scroller">
        <table mat-table [dataSource]="users()">

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let user">{{user.email}}</td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let user">{{user.role}}</td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef>Active</th>
                <td mat-cell *matCellDef="let user">{{user.active}}</td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef>Created</th>
                <td mat-cell *matCellDef="let user">{{formatTimestamp(user.created)}}</td>
            </ng-container>

            <ng-container matColumnDef="last_access">
                <th mat-header-cell *matHeaderCellDef>Last Access</th>
                <td mat-cell *matCellDef="let user">{{formatTimestamp(user.last_access)}}</td>
            </ng-container>

            <ng-container matColumnDef="sessions">
                <th mat-header-cell *matHeaderCellDef>Session Count</th>
                <td mat-cell *matCellDef="let user"
                    [ngClass]="{link: user.sessions}"
                    (click)="showUserSessions(user)">{{user.sessions}}</td>
            </ng-container>

            <ng-container matColumnDef="transactions">
                <th mat-header-cell *matHeaderCellDef>Trans. Count</th>
                <td mat-cell *matCellDef="let user">{{user.transactions}}</td>
            </ng-container>

            <ng-container matColumnDef="devices">
                <th mat-header-cell *matHeaderCellDef>Device Count</th>
                <td mat-cell *matCellDef="let user">{{user.devices}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let user">
                    <button mat-icon-button
                            title="Modify user"
                            color="primary"
                            [disabled]="isCurrentUser(user)"
                            (click)="modifyUser(user)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button
                            title="Delete user"
                            color="warn"
                            [disabled]="user.transactions > 0 || isCurrentUser(user)"
                            (click)="deleteUser(user)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row
                *matRowDef="let user; columns: columnsToDisplay"
                [ngClass]="{inactive: !user.active}"></tr>

        </table>
    </div>
}
