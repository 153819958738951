import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, TrustedDevice} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";
import {finalize} from "rxjs/operators";
import {UtilService} from "../../services/util.service";
import {BusyService} from "../../services/busy.service";
import {Constants} from "../../util/constants";
import {CacheService} from "../../services/cache.service";
import {NotificationService} from "../../services/notification.service";
import {Subscription} from "rxjs";
import {StateService} from "../../services/state.service";
import {MatDialog} from "@angular/material/dialog";
import {DeviceDialogComponent} from "../../dialogs/device-dialog/device-dialog.component";
import {CacheMessageMapper} from "../../util/cache-message-mapper";
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';

@Component({
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: true,
    imports: [MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatIconButton, MatIcon, MatTooltip, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatButton]
})
export class SettingsComponent implements OnInit, OnDestroy {

    readonly columnsToDisplay = ['info', 'host', 'alias', 'updated', 'actions'];

    devices: TrustedDevice[] = [];

    private notifSub?: Subscription;

    constructor(private authService: AuthService,
                private utilService: UtilService,
                private busyService: BusyService,
                private state: StateService,
                private cache: CacheService,
                private notificationService: NotificationService,
                private dialog: MatDialog,
                private alertService: AlertService) {
    }

    ngOnInit() {
        // Connect to push notification service if not yet connected (e.g. if page reloaded)
        this.notificationService.connect();
        const userId: number = this.state.get('userId')();
        this.loadTrustedDevices();
        this.notifSub = this.notificationService.message$.subscribe((message) => {
            const info = CacheMessageMapper.map(message, CacheMessageMapper.SETTINGS_PAT);
            if (info && info.label == userId.toString()) {
                console.log("settings notification received: must reload trusted devices...");
                this.cache.clear(...info.tags);
                this.reloadTrustedDevices();
            }
        });
    }

    ngOnDestroy() {
        this.notifSub?.unsubscribe();
    }

    private loadTrustedDevices() {
        if (this.cache.has('settings')) {
            this.devices = this.cache.get('settings');
            return;
        }
        this.busyService.showBusy();
        this.authService.getTrustedDevices().pipe(
            finalize(() => { this.busyService.showNotBusy() })
        ).subscribe({
            next: (devices) => {
                // Sort trusted devices with most recently updated shown first
                this.devices = devices.sort((a, b) => b.updated - a.updated);
                this.cache.set('settings', this.devices);
            },
            error: this.utilService.getErrorHandler("Error getting devices")
        });
    }

    private reloadTrustedDevices() {
        this.devices = [];
        this.loadTrustedDevices();
    }

    get isBusy(): boolean {
        return this.busyService.isBusy();
    }

    formatTimestamp(sd: string): string {
        return sd? Constants.US_TIMESTAMP_FORMAT.format(new Date(sd)) : "";
    }

    editDevice(dev: TrustedDevice) {
        this.dialog.open(DeviceDialogComponent, {
            disableClose: true,
            data: dev
        });
    }

    forgetDevice(dev: TrustedDevice) {
        this.alertService.confirm("Are you sure you want the system to forget this trusted device?").subscribe(
            (forget) => {
                if (forget) {
                    this.busyService.showBusy();
                    this.authService.forgetTrustedDevice(dev).pipe(
                        finalize(() => {this.busyService.showNotBusy()})
                    ).subscribe({
                        next: (thisDevice) => {
                            console.log("Trusted device forgotten");
                            //this.reloadTrustedDevices();
                        },
                        error: this.utilService.getErrorHandler("Error forgetting device")
                    })
                }
            }
        )
    }

    forgetTrustedDevices() {
        this.alertService.confirm("Are you sure you want the system to forget all your trusted devices?").subscribe(
            (forget) => {
                if (forget) {
                    this.busyService.showBusy();
                    this.authService.forgetTrustedDevices().pipe(
                        finalize(() => { this.busyService.showNotBusy() })
                    ).subscribe({
                        next: (count) => {
                            this.alertService.info(`${count} trusted devices forgotten`);
                            // this.reloadTrustedDevices();
                        },
                        error: this.utilService.getErrorHandler("Error forgetting devices")
                    })
                }
            }
        );
    }
}
