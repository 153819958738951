import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {AlertType} from "../../services/alert.service";
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';

const DEFAULT_OK_LABEL = "OK";

@Component({
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogTitle, MatIcon, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class AlertDialogComponent {

    alertType: AlertType = 'info';
    title: string = '';
    message: string = '';
    okLabel: string = DEFAULT_OK_LABEL;
    defaultTitle: string;
    iconType: string;

    constructor(private dialogRef: MatDialogRef<AlertDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
        this.alertType = this.data.alertType;
        this.defaultTitle = this.computeDefaultTitle();
        this.title = this.data.title? this.data.title : this.defaultTitle;
        this.message = this.data.message;
        this.okLabel = this.data.okLabel? this.data.okLabel : DEFAULT_OK_LABEL;
        this.iconType = this.computeIconType();
    }

    private computeIconType(): string {
        switch (this.alertType) {
            case 'error':
                return 'error';
            case 'warn':
                return 'warning';
            case 'info':
                return 'info';
            case 'debug':
                return 'bug_report';
        }
    }

    private computeDefaultTitle(): string {
        switch (this.alertType) {
            case 'error':
                return 'Error';
            case 'warn':
                return 'Warning';
            case 'info':
                return 'Information';
            case 'debug':
                return 'Debug';
        }
    }
}
