import {ChangeDetectionStrategy, Component, computed, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import { NavLinkComponent } from '../nav-link/nav-link.component';
import { CacheClearerComponent } from '../cache-clearer/cache-clearer.component';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CacheClearerComponent, NavLinkComponent]
})
export class NavBarComponent {

    domainsPat: RegExp = /^\/domains.*/;
    transactionsPat: RegExp = /^\/transactions.*/;
    dnsZonesPat: RegExp = /^\/dns-zones.*/;
    settingsPat: RegExp =/^\/settings.*/;
    adminPat: RegExp = /^\/admin.*/;
    bulkPat: RegExp = /^\/bulk.*/;

    constructor(private authService: AuthService) {
    }

    isSuperAdmin = computed(() => this.authService.isSuperAdmin());

    isLoggedIn = computed(() => this.authService.isLoggedIn())
}
