import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription, timer} from "rxjs";
import {StateService} from "./state.service";

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private sessionExpiration: Subject<void> = new Subject<void>();
    private sessionTimerSub?: Subscription;

    constructor(private state: StateService) {
    }

    /** Directly checks if the state info in local storage (if any) indicates that this browser has an expired session.
     * Useful for checking from a different tab or similar situations.
     */
    sessionHasExpired(): boolean {
        const maxIdleSeconds = this.state.get("idle")();
        const lastActive: number = this.state.get('lastActive')();
        if (maxIdleSeconds && lastActive) {
            const timeSinceActive: number = Date.now() - lastActive;
            const timeToLive: number = maxIdleSeconds * 1000;
            if (timeSinceActive > timeToLive)
                return true;
        }
        return false;
    }

    /** Returns an observable that will emit every time the user session expires.
     */
    sessionExpired(): Observable<void> {
        return this.sessionExpiration.asObservable();
    }

    /** Starts or restarts the session timer so after the max idle time expires, the session expiration observable will emit.
     * If session is already started, this will reset it to extend its expiration time to start from the current time.
     */
    restartSession() {
        const maxIdleSeconds = this.state.get("idle")();
        if (maxIdleSeconds) {
            this.clearSession();
            let timeToLive: number = maxIdleSeconds * 1000;
            const lastActive: number = this.state.get('lastActive')();
            const now: number = Date.now();
            if (lastActive) {
                const timeSinceActive: number = now - lastActive;
                if (timeSinceActive > timeToLive)
                    timeToLive = 0;
            }
            this.state.set('lastActive', now);
            this.sessionTimerSub = timer(timeToLive).subscribe(()=> {
                this.sessionExpiration.next();
            });
        }
    }

    /** Stops and clears session timer so session expiration observable will never emit (unless restarted)
     */
    clearSession() {
        if (this.sessionTimerSub) {
            this.sessionTimerSub.unsubscribe();
            this.sessionTimerSub = undefined;
        }
    }

}
