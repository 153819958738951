<div class="title">
    {{user? 'Update User' : 'Add User'}}
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field>
        <input matInput
               formControlName="email"
               placeholder="Email">
        <mat-error>{{formSignals.errors('email')()}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    id="role">
        <mat-label>Role</mat-label>
        <mat-select formControlName="role">
            @for (role of ROLES; track role) {
                <mat-option [value]="role">
                    {{role}}
                </mat-option>
            }
        </mat-select>
        <mat-error>{{formSignals.errors('role')()}}</mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="active"
                  id="active"
                  [matTooltip]="getCheckboxTooltip()">Active</mat-checkbox>

    <div class="actions">
        <button mat-raised-button
                color="primary"
                [disabled]="!formSignals.formValid() || !hasChanged()"
                type="submit"
                mat-dialog-close="">Submit</button>
        <button mat-raised-button
                type="button"
                mat-dialog-close="">Cancel</button>
    </div>
</form>
