import {ChangeDetectionStrategy, Component, computed, Inject} from '@angular/core';
import {AdminService, AdminUser} from "../../services/admin.service";
import {ValidatedForm} from "../../util/validated-form";
import {ReactiveFormsModule, UntypedFormBuilder, Validators} from "@angular/forms";
import {Constants} from "../../util/constants";
import {MAT_DIALOG_DATA, MatDialogClose} from "@angular/material/dialog";
import {BusyService} from "../../services/busy.service";
import {finalize} from "rxjs/operators";
import {AlertService} from "../../services/alert.service";
import {MatButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {FormSignals} from "../../util/form-signals";

@Component({
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, MatFormField, MatInput, MatError, MatLabel, MatSelect, MatOption, MatCheckbox, MatTooltip, MatButton, MatDialogClose]
})
export class UserDialogComponent extends ValidatedForm {
    readonly ROLES = ['Read Only', 'Admin User', 'Super Admin'];
    formSignals: FormSignals;

    constructor(protected override fb: UntypedFormBuilder,
                private adminService: AdminService,
                private busyService: BusyService,
                private alertService: AlertService,
                @Inject(MAT_DIALOG_DATA) public user?: AdminUser) {
        super(fb);
        this.initForm({
            email: {
                value: this.user?.email || '',
                validators: [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)],
                messages: {'required': Constants.ERR_REQUIRED, 'pattern': Constants.ERR_INVALID_EMAIL}
            },
            role: {
                value: this.user?.role || '',
                validators: [Validators.required],
                messages: {'required': Constants.ERR_REQUIRED}
            },
            active: {
                value: !!this.user?.active
            }
        });
        this.formSignals = new FormSignals(this.form, {
            email: this.fields!['email'].messages!,
            role: this.fields!['role'].messages!
        });
    }

    getCheckboxTooltip = computed(() =>
        this.formSignals.value<boolean>('active')()? "Deactivate user" : "Activate user"
    );

    hasChanged = computed(() => {
        if (!this.user)
            return true;
        return this.formSignals.value('email')().trim().toLowerCase() != this.user.email ||
               this.formSignals.value<boolean>('active')() != this.user.active ||
               this.formSignals.value('role')() != this.user.role;
    });

    submit() {
        const fields: any = this.fields;
        this.busyService.showBusy();
        if (this.user) {
            const email = fields['email'].value.trim().toLowerCase();
            this.adminService.updateUser(this.user.id, email, fields['active'].value, fields['role'].value).pipe(
                finalize(() => {this.busyService.showNotBusy()})
            ).subscribe({
                next: () => {
                },
                error: (err) => {
                    this.alertService.error("Error updating user: " + err.message);
                    console.error("Error updating user:", err);
                }
            });
        } else {
            this.adminService.addUser(fields['email'].value, fields['active'].value, fields['role'].value).pipe(
                finalize(() => {this.busyService.showNotBusy()})
            ).subscribe({
                next: () => {
                },
                error: (err) => {
                    this.alertService.error("Error adding user: " + err.message);
                    console.error("Error adding user:", err);
                }
            });
        }
    }

}
