import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PathService} from "./path.service";
import {Observable} from "rxjs";
import {OkInfo} from "../util/interfaces";
import {map} from "rxjs/operators";

interface CheckResponse {
    available: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private readonly contactRoot: string;

    constructor(private http: HttpClient,
                private pathService: PathService) {
        this.contactRoot = `${this.pathService.apiRoot}/contact`;
    }

    isAvailable(registryName: string, contactUid: string) {
        return this.http.post<CheckResponse>(`${this.contactRoot}/check`,
                                      {registryName: registryName, contactUid: contactUid}).pipe(
            map((out) => out.available)
        );
    }

    create(contactId: number, registryName: string, contactUid: string, authPassword: string|null=null): Observable<OkInfo> {
        return this.http.post<OkInfo>(this.contactRoot, {contactId: contactId, registryName: registryName,
                                                               contactUid: contactUid, authPassword: authPassword});
    }
}
