import {
    ChangeDetectionStrategy,
    Component,
    computed,
    OnDestroy,
    OnInit,
    signal, viewChild
} from '@angular/core';
import {finalize} from "rxjs/operators";
import {ValidatedForm} from "../../util/validated-form";
import {ReactiveFormsModule, UntypedFormBuilder} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {
    TransactionDetailsDialogComponent
} from "../../dialogs/transaction-details-dialog/transaction-details-dialog.component";
import {Operation} from "../../util/operation";
import {UtilService} from "../../services/util.service";
import {Transaction, TransactionService} from "../../services/transaction.service";
import {BusyService} from "../../services/busy.service";
import {Utils} from "../../util/utils";
import {Constants} from "../../util/constants";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {Observable, Subscription} from "rxjs";
import {DateTime} from "luxon";
import {NotificationService} from "../../services/notification.service";
import {CacheService} from "../../services/cache.service";
import {CacheMessageMapper} from "../../util/cache-message-mapper";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable, MatTableDataSource
} from '@angular/material/table';
import {MatButton} from '@angular/material/button';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {
    MatDatepickerToggle,
    MatDateRangeInput,
    MatDateRangePicker,
    MatEndDate,
    MatStartDate
} from '@angular/material/datepicker';
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {User} from "../../services/auth.service";
import {FormSignals} from "../../util/form-signals";

const MIN_WIDTH: number = 320;
const MAX_WIDTH: number = 800;

interface Op {
    id: number;
    description: string;
}

@Component({
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatRadioGroup, MatRadioButton, ReactiveFormsModule, MatFormField, MatLabel, MatDateRangeInput, MatStartDate, MatEndDate, MatDatepickerToggle, MatSuffix, MatDateRangePicker, MatSelect, MatOption, MatButton, MatPaginator, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow]
})
export class TransactionsComponent extends ValidatedForm implements OnInit, OnDestroy {

    readonly operations: Op[] = [
        {id: 0, description: 'ALL'},
        {id: Operation.ChangeNameServers, description: "Change nameservers"},
        // {id: Operation.ChangeContactInfo, description: "Change contact info"},
        {id: Operation.Create, description: "Create"},
        // {id: Operation.Delete, description: "Delete"},
        // {id: Operation.AvailabilityCheck, description: "Availability check"},
        // {id: Operation.SyncFromRegistry, description: "Sync database from registry"},
        // {id: Operation.SubmitTransfer, description: "Submit Transfer"},
        {id: Operation.Unlock, description: "Unlock"},
        {id: Operation.Lock, description: "Lock"},
        // {id: Operation.CompleteTransfer, description: "Complete Transfer"},
        // {id: Operation.PrivacyInfo, description: "Privacy Info"},
        {id: Operation.ApproveTransfer, description: "Approve Transfer"},
        // {id: Operation.BulkRenew, description: "Bulk Renew"},
        // {id: Operation.BulkRestore, description: "Bulk Restore"},
        // {id: Operation.BulkRestoreReport, description: "Bulk Restore Report"}
    ];

    public readonly DEFAULT_PAGE_SIZE = 10;
    public readonly MAX_TRANSACTIONS = 20;

    readonly today: Date = new Date();

    /** Format that produces SQL compatible dates: yyyy-mm-dd */
    readonly dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('sv-SE', {year: 'numeric', month: '2-digit', day: '2-digit'});

    users = signal<User[]>([]);
    rangeType = signal<'custom' | 'last_n' | 'last_30_days' | 'last_60_days'>('last_n');
    hasSearched = signal(false);
    transactions = signal<Transaction[]>([]);
    paginator = viewChild<MatPaginator>('paginator');
    dataSource = signal(new MatTableDataSource<Transaction>([]));

    readonly formSignals: FormSignals;
    private notifSub?: Subscription;
    readonly columnsToDisplay = ['id', 'user', 'op', 'started', 'notes', 'actions'];



    constructor(protected override fb: UntypedFormBuilder,
                private transactionService: TransactionService,
                private utilService: UtilService,
                private busyService: BusyService,
                private cache: CacheService,
                private notificationService: NotificationService,
                private dialog: MatDialog) {
        super(fb);
        this.initForm({
            start: { value: this.today },
            end: {value: this.today} ,
            user: {value: 0},
            operation: {value: 0}
        });
        this.formSignals = new FormSignals(this.form, {});
    }

    ngOnInit() {
        // Connect to push notification service if not yet connected (e.g. if page reloaded)
        this.notificationService.connect();

        this.busyService.showBusy();
        this.utilService.getUsers().pipe(
            finalize(() => { this.busyService.showNotBusy() })
        ).subscribe({
            next: (users) => {
                users.splice(0, 0,{id: 0, email: 'ALL'})
                this.users.set(users);
                this.loadRangeTransactions(this.getRangeObservable()!);
            },
            error: this.utilService.getErrorHandler("error getting users")
        });
        // Update domain list when server tells us it has changed
        this.notifSub = this.notificationService.message$.subscribe((message) => {
            const info = CacheMessageMapper.map(message, CacheMessageMapper.TRANSACTIONS_PAT);
            if (info) {
                console.log("transaction notification received: must reload transactions...");
                this.cache.clear(...info.tags);
                this.transactions.set([]);
                this.reloadTransactions();
            }
        });
    }

    ngOnDestroy() {
        this.notifSub?.unsubscribe();
    }

    isBusy = computed(() => this.busyService.isBusy())

    transRange = computed(() => {
        if (!this.paginator())
            return [];
        const pageSize = this.paginator()!.pageSize;
        const offset = this.paginator()!.pageIndex * pageSize;
        return this.transactions().slice(offset, offset + pageSize);
    });

    formatTimestamp(sd: string): string {
        return sd? Constants.US_TIMESTAMP_FORMAT.format(new Date(sd)) : "";
    }

    changeRangeType(ev: MatRadioChange) {
        if (ev.value != this.rangeType()) {
            this.hasSearched.set(false);
            this.rangeType.set(ev.value);
            const transactionObs: Observable<Transaction[]>|null = this.getRangeObservable();
            this.transactions.set([]);
            if (transactionObs)
                this.loadRangeTransactions(transactionObs);
        }
    }

    private loadRangeTransactions(transactionObs: Observable<Transaction[]>) {
        const cacheKey = `transactions.${this.rangeType()}`;
        if (this.cache.has(cacheKey)) {
            this.transactions.set(this.cache.get(cacheKey));
            this.updateTransactions(this.transactions());
        } else {
            this.busyService.showBusy();
            transactionObs.pipe(
                finalize(() => {
                    this.busyService.showNotBusy()
                })
            ).subscribe({
                next: (transactions) => {
                    this.updateTransactions(transactions);
                },
                error: this.utilService.getErrorHandler("error getting transactions")
            });
        }
    }

    private updateTransactions(transactions: Transaction[]) {
        this.transactions.set(transactions);
        this.hasSearched.set(true);
        let source = new MatTableDataSource<Transaction>(transactions);
        if (this.paginator())
            this.paginator()!.pageIndex = 0;
        source.paginator = this.paginator() || null;
        this.dataSource.set(source);
        this.cache.set(`transactions.${this.rangeType()}`, transactions);
    }

    private getRangeObservable(): Observable<Transaction[]> | null {
        switch(this.rangeType()) {
            case 'last_n':
                return this.transactionService.getAll(this.MAX_TRANSACTIONS);
            case 'last_30_days': {
                const today = DateTime.now().toISODate();
                const start = DateTime.now().minus({days: 30}).toISODate();
                return this.transactionService.search(start, today);
            }
            case 'last_60_days': {
                const today = DateTime.now().toISODate();
                const start = DateTime.now().minus({days: 60}).toISODate();
                return this.transactionService.search(start, today);
            }
            case 'custom':
                return null;
        }
    }

    private reloadTransactions() {
        const transactionObs: Observable<Transaction[]> | null = this.getRangeObservable();
        if (transactionObs)
            this.loadRangeTransactions(transactionObs);
        else
            this.submit();
    }

    showDetail(trans: Transaction) {
        this.dialog.open(TransactionDetailsDialogComponent, {
            minWidth: MIN_WIDTH,
            maxWidth: MAX_WIDTH,
            data: {
                transactionId: trans.transId,
                actions: trans.actions
            }
        });
    }

    userEmail(userId: number): string {
        for (let user of this.users())
            if (user['id'] == userId)
                return Utils.MakeEmailBreakable(user['email']);
        return '?'
    }

    linkDomains(text: string): string {
        // Find any string looking like *.com, *.net, *.org and turn it into an <a> link to the domains page
        const pat= /\S+\.(?:com|net|org)/g;
        return text.replace(pat, "<a href='/domains/$&'>$&</a>");
    }

    submit() {
        if (this.fields) {
            const start = this.dateFormat.format(this.fields['start'].value);
            const end = this.dateFormat.format(this.fields['end'].value);
            const userId = this.fields['user'].value || null;
            const opId = this.fields['operation'].value || null;
            this.busyService.showBusy();
            this.hasSearched.set(false);
            this.transactionService.search(start, end, userId, opId).pipe(
                finalize(() => {
                    this.busyService.showNotBusy();
                })
            ).subscribe({
                next: (transactions) => {
                    this.updateTransactions(transactions);
                },
                error: this.utilService.getErrorHandler("error getting transactions")
            });
        }
    }

    private breakOpName(trans: Transaction): Transaction {
        // Break camel-case operation names into multiple words separate by spaces
        const name: string = trans.operation;
        const pat: RegExp = /[a-z][A-Z]/g;
        let match;
        let parts = [];
        let start = 0;
        while (match = pat.exec(name)) {
            parts.push(name.substring(start, match.index+1));
            start = match.index+1;
        }
        if (parts.length > 0) {
            parts.push(name.substring(start));
            trans.operation = parts.join(" ");
        }
        return trans;
    }
}
