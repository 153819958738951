import {Component, OnInit} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatDialogContent } from '@angular/material/dialog';

@Component({
    template: "<div mat-dialog-content><mat-spinner></mat-spinner></div>",
    standalone: true,
    imports: [MatDialogContent, MatProgressSpinner]
})
export class BusyOverlayComponent {

}
