@if (isLoggedIn()) {
    <span>
        <app-cache-clearer></app-cache-clearer>
        <app-nav-link text="Domains" url="/domains" [active_pattern]="domainsPat"></app-nav-link>
        <app-nav-link text="Bulk" url="/bulk" [active_pattern]="bulkPat"></app-nav-link>
        <app-nav-link text="Transactions" url="/transactions" [active_pattern]="transactionsPat"></app-nav-link>
        <app-nav-link text="DNS Zones" url="/dns-zones" [active_pattern]="dnsZonesPat"></app-nav-link>
        <app-nav-link text="Settings" url="/settings" [active_pattern]="settingsPat"></app-nav-link>
        @if (isSuperAdmin()) {
            <app-nav-link text="Admin" url="/admin" [active_pattern]="adminPat"></app-nav-link>
        }
    </span>
}
