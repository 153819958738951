import {
    ChangeDetectionStrategy,
    Component,
    computed,
    OnDestroy,
    OnInit,
    signal, viewChild
} from '@angular/core';
import {StateService} from "../../services/state.service";
import {DomainInfo, DomainService, EppFullDomainInfo, NameServerGroup} from "../../services/domain.service";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../../services/alert.service";
import {finalize, map, mergeMap} from "rxjs/operators";
import {formatNumber, NgClass} from "@angular/common";
import {BusyService} from "../../services/busy.service";
import {Transaction, TransactionService} from "../../services/transaction.service";
import {
    TransactionDetailsDialogComponent
} from "../../dialogs/transaction-details-dialog/transaction-details-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Utils} from "../../util/utils";
import {UtilService} from "../../services/util.service";
import {AuthService, User} from "../../services/auth.service";
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Constants} from "../../util/constants";
import {Subscription, timer} from "rxjs";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {NotificationService} from "../../services/notification.service";
import {OkInfo} from "../../util/interfaces";
import {ActivatedRoute, Router} from "@angular/router";
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {CacheService} from "../../services/cache.service";
import {CacheMessageMapper} from "../../util/cache-message-mapper";
import {MatLabel, MatSelect} from '@angular/material/select';
import {MatTooltip} from '@angular/material/tooltip';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatOption} from '@angular/material/core';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField, MatSuffix} from '@angular/material/form-field';
import {FormSignals} from "../../util/form-signals";
import {DateTime} from "luxon";
import {Domain} from "../../services/for-sale.service";

const MIN_WIDTH: number = 320;
const MAX_WIDTH: number = 800;
const MIN_SEARCH_LENGTH = 3;
const BLACKOUT_PERIOD = 2000;

@Component({
    templateUrl: './domain.component.html',
    styleUrls: ['./domain.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, MatFormField, MatInput, MatAutocompleteTrigger, MatProgressSpinner, MatIconButton, MatSuffix, MatIcon, MatAutocomplete, MatOption, MatError, MatButton, MatCheckbox, MatTooltip, FormsModule, NgClass, MatSelect, MatPaginator, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatLabel]
})
export class DomainComponent implements OnInit, OnDestroy {

    autoComplete = viewChild<MatAutocomplete>('auto');
    paginator = viewChild<MatPaginator>('paginator');

    form: UntypedFormGroup;
    private prefix: string = "";
    private allMatches: string[] = [];
    private filterPrefix: string = "";
    private users: User[] = [];
    searching = signal(false);

    domainInfo = signal<DomainInfo|undefined>(undefined);
    eppDomainInfo = signal<EppFullDomainInfo|undefined>(undefined);
    allowToggleInRegistry = signal(false);
    // 2FA code to authorize the enabling of the Delete button
    allowDeleteCode = signal('');
    allowDelete = signal(false);
    groups = signal<NameServerGroup[]>([]);
    selectedGroup = signal('');
    dataSource = signal<MatTableDataSource<Transaction>|undefined>(undefined);
    private notifSub?: Subscription;
    private ignoreNotificationsUntilTime: number = 0;
    private readonly userRole: string;
    readonly isAdmin: boolean;


    readonly columnsToDisplay = ['id', 'user', 'op', 'started', 'notes', 'actions'];
    readonly DEFAULT_PAGE_SIZE = 10;

    eppBusy = signal(false);
    isBusy = computed(() => this.busyService.isBusy());

    transactions = signal<Transaction[]>([]);
    hasTransactions = computed(() => this.transactions().length > 0);
    hasPages = computed(() => this.transactions().length > this.DEFAULT_PAGE_SIZE);

    haveForm = signal(true);
    filteredMatches = signal<string[]>([]);

    formSignals: FormSignals;

    constructor(private fb: UntypedFormBuilder,
                private state: StateService,
                private domainService: DomainService,
                private authService: AuthService,
                private transactionService: TransactionService,
                private alertService: AlertService,
                private busyService: BusyService,
                private utilService: UtilService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private router: Router,
                private cache: CacheService,
                private dialog: MatDialog) {
        this.form = this.fb.group({
            domain: ['', {validators: [Validators.pattern(Constants.DOMAIN_REGEX), Validators.required]} ]
        });
        this.formSignals = new FormSignals(this.form, {
            domain: {required: Constants.ERR_REQUIRED, pattern: "Not a valid domain"}
        });

        this.userRole = this.state.get('userRole')();
        this.isAdmin = ["Admin", "Super Admin"].includes(this.userRole);
        let domainName = this.route.snapshot.params['domain'];
        if (domainName) {
            this.state.set('domainName', domainName);
            this.router.navigateByUrl('/domains').then();
        } else {
            domainName = this.state.get('domainName')();
            if (domainName) {
                this.state.clear('domainName');
                this.initialize(domainName);
            }
        }
    }

    ngOnInit() {
        // Connect to push notification service if not yet connected (e.g. if page reloaded)
        this.notificationService.connect();
        // Hook up domain control to manage dynamic searches as user types
        const domainControl = this.form.get('domain');
        domainControl?.valueChanges.subscribe((ev) => {
            const value: string = domainControl.value?.toLowerCase();
            if (!value)
                return;
            if (!this.prefix && value.length >= MIN_SEARCH_LENGTH && domainControl.invalid)
                this.updateMatches(value);
            // If input value is just a longer string with the original prefix: just filter the options from stored
            // array dynamically
            else if (this.prefix && value.length >= this.prefix.length && value.startsWith(this.prefix)) {
                this.filterPrefix = value;
                this.updateFilteredMatches();
                // If input value is no longer an extension of prefix: if shorter than min length, just clear all matches
                // otherwise set it as the new prefix and update matches
            } else if (!value.startsWith(this.prefix)) {
                if (value.length < MIN_SEARCH_LENGTH) {
                    this.allMatches = [];
                    this.prefix = this.filterPrefix = "";
                    this.updateFilteredMatches()
                } else if (domainControl.invalid)
                    this.updateMatches(value);
            }
        });

        // Initialize name server groups info in state, if not there yet
        this.groups.set(this.state.get('groups')());
        if (!this.groups()) {
            this.busyService.showBusy();
            this.domainService.getNameServerGroups().pipe(
                finalize(() => { this.busyService.showNotBusy() })
            ).subscribe( {
                next: (groups) => {
                    this.state.set("groups", groups);
                    this.groups.set(groups);
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
                    this.alertService.error("Error retrieving name server groups: " + err.message);
                }
            })
        }
        // Register to receive push notifications
        this.notifSub = this.notificationService.message$.subscribe((message) => {
            // We only care if it's about a transaction or a domain change for the currently displayed domain (if any)
            if (this.domainInfo()) {
                const name: string = this.domainInfo()!.name;
                const transInfo = CacheMessageMapper.map(message, CacheMessageMapper.TRANSACTIONS_PAT);
                const domInfo = CacheMessageMapper.map(message, CacheMessageMapper.DOMAINS_PAT);
                // If it's a change in transactions for this domain then clear any cached transactions info
                if (transInfo && transInfo.label == name)
                    this.cache.clear(...transInfo.tags);
                // If it's either a change in transactions or domain info for this domain...
                if (transInfo && transInfo.label == name || domInfo && domInfo.label == name) {
                    // ... update its info from server, unless we are in a blackout period, to ignore notifications
                    // triggered by ourselves
                    const now = new Date().getTime();
                    if (now > this.ignoreNotificationsUntilTime)
                        this.update();
                    else
                        console.log("Ignoring notification due to blackout period");
                }
            }
        });
    }

    ngOnDestroy() {
        this.notifSub?.unsubscribe();
    }

    private updateMatches(value: string) {
        this.prefix = value;
        this.filterPrefix = "";
        this.searching.set(true);
        this.domainService.find(value).pipe(
            finalize(() => {this.searching.set(false)})
        ).subscribe({
                next: (matches) => {
                    if (this.searching()) {
                        this.allMatches = matches;
                        this.updateFilteredMatches();
                    }
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
                    this.alertService.error("Error finding domain matches: " + err.message);
                }
            }
        )
    }

    private updateFilteredMatches() {
        this.filteredMatches.set(this.allMatches.filter((m) => m.includes(this.filterPrefix)));
        //console.log(`Filter - prefix: ${this.filterPrefix} - matches: ${this.filteredMatches.length}`);
    }

    clearSearch() {
        this.form.controls['domain'].patchValue('');
        this.allMatches = [];
        this.filteredMatches.set([]);
        this.searching.set(false);
    }

    clearSearchIfClosed() {
        if (!this.autoComplete()!.isOpen)
            this.clearSearch();
    }

    submitIfClosed() {
        if (!this.autoComplete()!.isOpen && !this.form.invalid && !this.isBusy())
            this.submit();
    }

    submit() {
        this.initialize(this.form.controls['domain']?.value?.toLowerCase());
    }

    private initialize(domainName: string) {
        this.busyService.showBusy();
        this.domainInfo.set(undefined);
        this.transactions.set([]);
        this.domainService.getInfo(domainName).pipe(
            finalize(() => {
                this.busyService.showNotBusy();
            })
        ).subscribe({
            next: (out) => {
                this.domainInfo.set(out);
                this.form.reset();
                this.filteredMatches.set([]);
                this.filterPrefix = '';
                this.selectedGroup.set(this.domainInfo()!.ns_group.name);
                // Recreate the form so it will show as valid before typing new domain or prefix
                this.haveForm.set(false);
                timer(0).subscribe(() => {
                    this.haveForm.set(true);
                    this.updateEppInfo();
                    this.getTransactionInfo();
                });
            },
            error: (err: HttpErrorResponse) => {
                if (err.status == 404)
                    this.alertService.confirm("Domain not found in our db.<br/>Do you want to check if it is available?")
                        .subscribe((yesPlease) => {
                            if (yesPlease)
                                this.router.navigateByUrl(`/domains/buy/${domainName}`).then();
                        });
                else {
                    console.error(err);
                    this.alertService.error("Error getting domain info: " + err.message);
                }
            }
        });
    }

    update() {
        if (this.domainInfo()) {
            this.busyService.showBusy();
            this.allowToggleInRegistry.set(false);
            this.domainService.getInfo(this.domainInfo()!.name).pipe(
                finalize(() => {this.busyService.showNotBusy()})
            ).subscribe({
                next: (out) => {
                    this.domainInfo.set(out);
                    this.selectedGroup.set(this.domainInfo()!.ns_group.name);
                    this.updateEppInfo();
                    this.getTransactionInfo();
                },
                error: (err: HttpErrorResponse) => {
                    this.alertService.error("Error updating domain info: " + err.error || err.message);
                }
            });
        }
    }

    getInRegistryButtonLabel = computed(() =>
        this.domainInfo()?.in_registry? "Mark Not in Registry" : "Mark In Registry"
    )

    toggleInRegistry() {
        if (this.domainInfo()) {
            this.alertService.confirm("Are you sure you want to update this domain<br/>in our database to change its in-registry status?")
                .subscribe((sure) => {
                    if (sure) {
                        const newFlag: boolean = !this.domainInfo()!.in_registry;
                        this.busyService.showBusy();
                        this.ignoreNotificationsUntilTime = new Date().getTime() + BLACKOUT_PERIOD;
                        this.domainService.markInRegistry(this.domainInfo()!.name, newFlag).pipe(
                            finalize(() => {this.busyService.showNotBusy()})
                        ).subscribe({
                            next: (out) => {
                                this.domainInfo()!.in_registry = newFlag;
                                this.update();
                            },
                            error: (err) => {
                                console.error(`Error updating in-registry flag for ${this.domainInfo()?.name}`, err);
                            }
                        });
                    }
                });
        }
    }

    getInInquiryButtonLabel = computed(() =>
        this.domainInfo()?.in_inquiry? "Mark Not in Inquiry" : "Mark In Inquiry"
    )

    toggleInInquiry() {
        if (this.domainInfo()) {
            const newFlag: boolean = !this.domainInfo()!.in_inquiry;
            this.busyService.showBusy();
            this.ignoreNotificationsUntilTime = new Date().getTime() + BLACKOUT_PERIOD;
            this.domainService.markInInquiry(this.domainInfo()!.name, newFlag).pipe(
                finalize(() => {this.busyService.showNotBusy()})
            ).subscribe({
                next: (out) => {
                    this.domainInfo()!.in_inquiry = newFlag;
                    this.update();
                },
                error: (err) => {
                    console.error(`Error updating in-inquiry flag for ${this.domainInfo()?.name}`, err);
                }
            });
        }
    }

    canChangeGroup = computed(() =>
        !!this.selectedGroup() && !!this.domainInfo()?.in_registry && !this.hasServerStatus() && this.isAdmin
    );

    hasDifferentGroup = computed(() =>
        this.domainInfo()?.ns_group.name != this.selectedGroup()
    );

    changeGroup() {
        if (!this.domainInfo())
            return;
        this.busyService.showBusy();
        this.domainService.changeNameServerGroup(this.domainInfo()!.name, this.selectedGroup()).pipe(
            finalize(() => { this.busyService.showNotBusy() })
        ).subscribe({
            next: (out) => {
                // this.domainInfo()!.ns_group = this.groups().find((g) => g.name == this.selectedGroup()) as NameServerGroup;
                // this.updateEppInfo();
                this.update();
            }
        })
    }

    private updateEppInfo() {
        this.eppDomainInfo.set(undefined);
        if (!this.domainInfo() || !this.domainInfo()!.in_registry)
          return;
        this.eppBusy.set(true);
        this.eppDomainInfo.set(undefined);
        this.domainService.eppGetInfo(this.domainInfo()!.name).pipe(
            finalize(() => {this.eppBusy.set(false)})
        ).subscribe({
            next: (out) => {
                console.log("EPP info:", out);
                if (out.in_registry) {
                    this.eppDomainInfo.set(out as EppFullDomainInfo);
                    //this.validateAgainstEpp();
                }
            },
            error: (err: HttpErrorResponse) => {
                this.alertService.error("Error retrieving EPP domain info: " + err.error || err.message);
            }
        });
    }

    private getTransactionInfo() {
        this.busyService.showBusy();
        this.utilService.getUsers().pipe(
            mergeMap((users) => {
                this.users = users;
                return this.transactionService.domainTransactions(this.domainInfo()!.name);
            }),
            finalize(() => { this.busyService.showNotBusy() })
        ).subscribe({
            next: (transactions) => {
                this.transactions.set(transactions || []);
                this.dataSource.set(new MatTableDataSource<Transaction>(transactions));
                this.dataSource()!.paginator = this.paginator()!;
            },
            error: (err: HttpErrorResponse) => {
                this.alertService.error("Error retrieving domain transaction info: " + err.error || err.message);
            }
        });
    }

    userEmail(userId: number): string {
        for (let user of this.users)
            if (user['id'] == userId)
                return Utils.MakeEmailBreakable(user['email']);
        return '?'
    }

    showDetail(trans: Transaction) {
        this.dialog.open(TransactionDetailsDialogComponent, {
            minWidth: MIN_WIDTH,
            maxWidth: MAX_WIDTH,
            data: {
                transactionId: trans.transId,
                actions: trans.actions
            }
        });
    }

    formatTimestamp(ts: number | undefined | null) {
        if (!ts)
            return '';
        // Hack to include any milliseconds after seconds (assuming ts ends like this: HH:MM:ss AM|PM)
        // Find :\d\d and insert milliseconds difference.
        const msec = formatNumber(ts % 1000, "en-US", "3.0-0");
        const sTimestamp = new Date(ts).toLocaleString();
        return sTimestamp.replace(/(:\d\d) /, `$1.${msec} `);
    }

    private validateAgainstEpp() {
        if (!this.eppDomainInfo() || !this.domainInfo())
            return;
        let errors = [];
        // Check if in_registry flag matches
        if (this.domainInfo()!.in_registry != this.eppDomainInfo()!.in_registry)
            errors.push("in_registry flag mismatch - EPP has: " + this.eppDomainInfo()!.in_registry)
        // Check if NS hosts match
        const sameHosts = this.domainInfo()!.ns_group.hosts.every((h) => this.eppDomainInfo()!.ns_hosts.includes(h));
        if (!sameHosts)
            errors.push("NS hosts mismatch - EPP has: " + this.eppDomainInfo()!.ns_hosts.join(", "));
        // Check if timestamps match
        if (this.domainInfo()!.registered != this.eppDomainInfo()!.created)
            errors.push("Registration time mismatch - EPP has: " + this.formatTimestamp(this.eppDomainInfo()!.created));
        if (this.domainInfo()!.expires != this.eppDomainInfo()!.expires)
            errors.push("Expiration time mismatch - EPP has: " + this.formatTimestamp(this.eppDomainInfo()!.expires));
        if (this.domainInfo()!.updated != this.eppDomainInfo()!.updated)
            errors.push("Last-updated time mismatch - EPP has: " + this.formatTimestamp(this.eppDomainInfo()!.updated));
        if (errors)
            this.alertService.warn(errors.join("<br/>"));
    }

    inRegistryMismatch = computed(() =>
        !!this.eppDomainInfo() && this.domainInfo()?.in_registry != this.eppDomainInfo()!.in_registry
    );

    nsMismatch = computed(() => {
        // Check if NS hosts match
        return !!this.eppDomainInfo() &&
            !this.domainInfo()!.ns_group.hosts.every((h) => this.eppDomainInfo()!.ns_hosts.includes(h));
    });

    registeredMismatch = computed(() =>
        !!this.eppDomainInfo() &&  this.domainInfo()?.registered != this.eppDomainInfo()!.created
    );

    expiresMismatch = computed(() =>
        !!this.eppDomainInfo() && this.domainInfo()?.expires != this.eppDomainInfo()!.expires
    );

    updatedMismatch = computed(() =>
        !!this.eppDomainInfo() && this.domainInfo()?.updated != this.eppDomainInfo()!.updated
    );

    anyDateMismatch = computed(() =>
        this.registeredMismatch() || this.expiresMismatch() || this.updatedMismatch()
    );

    getStatuses = computed(() =>
        this.eppDomainInfo()? this.eppDomainInfo()!.statuses.join(", ") : ''
    );

    isServerStatus(status: string): boolean {
        // serverHold, serverRenewProhibited, serverTransferProhibited, serverUpdateProhibited, serverDeleteProhibited
        return status.startsWith("server");
    }

    hasServerStatus = computed(() =>
        !!this.eppDomainInfo() && this.eppDomainInfo()!.statuses.some((s) => this.isServerStatus(s))
    );

    hasPendingDeleteStatus = computed(() =>
        !!this.eppDomainInfo() && this.eppDomainInfo()!.statuses.includes('pendingDelete')
    );

    // A domain is within the delete grade period if it has been renewed, and it hasn't been more than 45 days since the
    // previous expiration timestamp (which in turn is the current expiration timestamp minus one year).
    private isDomainWithinDeleteGracePeriod(info: DomainInfo): boolean {
        const dt = DateTime.fromMillis(info.expires);
        return dt.minus({years: 1}).plus({days: 45}) > DateTime.now();
    }

    inDeleteGracePeriod = computed(() =>
        !!this.domainInfo() && this.isDomainWithinDeleteGracePeriod(this.domainInfo()!)
    );

    copyAuthCode() {
        navigator.clipboard.writeText(this.eppDomainInfo()?.auth_code || '').then(() => {
            this.alertService.info("Auth code copied to clipboard");
        });
    }

    syncDates() {
        this.alertService.confirm("Are you sure you want to update the domain dates in our database to match the EPP values?")
            .subscribe((doIt) => {
                if (doIt) {
                    this.busyService.showBusy();
                    this.domainService.syncDomainDates(this.domainInfo()!.name).pipe(
                        finalize(() => {this.busyService.showNotBusy()})
                    ).subscribe({
                        next: (info: OkInfo) => {
                            if (info.ok)
                                this.update();
                            else
                                this.alertService.warn("Nothing to sync!");
                        }
                    });
                }
            });
    }

    lock() {
        this.alertService.confirm("Are you sure you want to Lock this domain?").subscribe((doIt) => {
            if (doIt) {
                this.busyService.showBusy();
                this.domainService.lock(this.domainInfo()!.name).pipe(
                    finalize(() => {this.busyService.showNotBusy()})
                ).subscribe({
                    next: (out) => {
                        this.update();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.alertService.error("Error locking domain: " + err.error || err.message);
                    }
                });
            }
        });
    }

    unlock() {
        this.alertService.confirm("Are you sure you want to Unlock this domain?").subscribe((doIt) => {
            if (doIt) {
                this.busyService.showBusy();
                this.domainService.unlock(this.domainInfo()!.name).pipe(
                    finalize(() => {
                        this.busyService.showNotBusy()
                    })
                ).subscribe({
                    next: (out) => {
                        this.update();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.alertService.error("Error unlocking domain: " + err.error || err.message);
                    }
                });
            }
        });
    }

    canLock = computed(() =>
        !this.domainInfo()!.locked && this.domainInfo()!.in_registry && !this.hasServerStatus() && this.isAdmin
    );

    canUnLock = computed(() =>
        this.domainInfo()!.locked && this.domainInfo()!.in_registry && !this.hasServerStatus() && this.isAdmin
    );

    canTransfer = computed(() =>
        // A domain can only be transferred when it has been unlocked and while it is still marked as part of our registry
        !this.domainInfo()!.locked && this.domainInfo()!.in_registry && !this.hasServerStatus() && this.isAdmin
    );

    couldBeDeleted = computed(() =>
        this.domainInfo()!.in_registry && !this.hasPendingDeleteStatus() &&
        !this.domainInfo()!.in_inquiry && !this.domainInfo()!.pick && this.isAdmin
    );

    canAllowDelete = computed(() =>
        this.couldBeDeleted() && /^\d{6}$/.test(this.allowDeleteCode())
    );

    canDelete = computed(() =>
        this.couldBeDeleted() && this.allowDelete()
    );

    approveTransfer() {
        this.alertService.confirm("Are you sure you want to Approve Transfer for this domain?").subscribe((doIt) => {
            if (doIt) {
                this.busyService.showBusy();
                this.domainService.approveTransfer(this.domainInfo()!.name).pipe(
                    finalize(() => {
                        this.busyService.showNotBusy()
                    })
                ).subscribe({
                    next: (out) => {
                        this.update();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.alertService.error("Error approving transfer: " + err.error || err.message);
                    }
                });
            }
        });
    }

    /** Validates the entered 2FA code and, if  valid, enables the Delete button
     */
    authorizeDelete() {
        this.busyService.showBusy()
        this.authService.validateMfaCode(this.allowDeleteCode()).pipe(
            finalize(() => {
                this.busyService.showNotBusy()
            })
        ).subscribe( {
            next: (valid) => {
                if (!valid)
                    this.alertService.error("The supplied 2FA code is not valid");
                else {
                    this.allowDeleteCode.set('')
                    this.allowDelete.set(true);
                }
            },
            error: (err: HttpErrorResponse) => {
                this.alertService.error("Error validating 2FA code: " + err.error?.message || err.message);
            }
        });
    }

    delete() {
        this.alertService.confirm("<p>Are you really sure you want to Delete this domain?</p><p>Note this action cannot be undone!</p>")
        .subscribe((doIt) => {
            if (doIt) {
                this.busyService.showBusy();
                this.domainService.delete(this.domainInfo()!.name).pipe(
                    finalize(() => {this.busyService.showNotBusy()})
                ).subscribe({
                    next: () => {
                        this.update();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.alertService.error("Error deleting domain: " + err.error || err.message);
                    }
                });
            }
        });
    }

}
