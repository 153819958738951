import {Constants} from './constants';
import {PasswordQualityOptions} from './app-validators';

export class Passwords {

    static readonly DEFAULT_OPTIONS: PasswordQualityOptions = {
        minLength: Constants.MIN_PASSWORD_LENGTH,
        letters: true,
        digits: true,
        allowSpaces: false
    };

    static GetHint(o:PasswordQualityOptions = this.DEFAULT_OPTIONS) {
        let hint = `At least ${o.minLength} characters, containing `;
        let containing = [];
        if (o.letters)
            containing.push('letters');
        if (o.digits)
            containing.push('digits');
        if (o.specialChars)
            containing.push('symbols');
        hint += containing.join(", ");
        if (!o.allowSpaces)
            hint += " and no spaces";
        return hint;
    }
}
