<h1>
    <span routerLink="/admin" class="link" title="Back to Admin root">Admin</span>
    <span id="user"> &gt; {{userEmail}}</span>
</h1>

<h2>Sessions for user: {{userEmail}}</h2>

<div [hidden]="isBusy() || !hasPages()" class="paginator_row">
    <mat-paginator #paginator
                   [length]="dataSource()?.data?.length || 0"
                   [pageIndex]="0"
                   [pageSize]="DEFAULT_PAGE_SIZE"
                   [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
</div>
<div id="table_scroller">
    @if (!isBusy() && !!dataSource() && dataSource()!.data.length) {
        <table mat-table
               [dataSource]="dataSource()!">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let sess">{{sess.id}}</td>
            </ng-container>

            <ng-container matColumnDef="pwa">
                <th mat-header-cell *matHeaderCellDef>PWA</th>
                <td mat-cell *matCellDef="let sess">{{sess.pwa || false}}</td>
            </ng-container>

            <ng-container matColumnDef="expires">
                <th mat-header-cell *matHeaderCellDef>Expires</th>
                <td mat-cell *matCellDef="let sess">{{formatTimestamp(sess.expires)}}</td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef>Created</th>
                <td mat-cell *matCellDef="let sess">{{formatTimestamp(sess.created)}}</td>
            </ng-container>

            <ng-container matColumnDef="updated">
                <th mat-header-cell *matHeaderCellDef>Updated</th>
                <td mat-cell *matCellDef="let sess">{{formatTimestamp(sess.updated)}}</td>
            </ng-container>

            <ng-container matColumnDef="closed">
                <th mat-header-cell *matHeaderCellDef>Closed</th>
                <td mat-cell *matCellDef="let sess">{{formatTimestamp(sess.closed)}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row
                *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
    }
</div>
