
<div id="submenu">
    <span class="selector"
          (click)="select('forsale')"
          [ngClass]="{link: currentZone() != 'forsale', selected: currentZone() == 'forsale'}">Domains For Sale</span>

    <span class="selector"
          (click)="select('auction')"
          [ngClass]="{link: currentZone() != 'auction', selected: currentZone() == 'auction'}">Domains For Auction</span>

    <span class="selector"
          (click)="select('adult')"
          [ngClass]="{link: currentZone() != 'adult', selected: currentZone() == 'adult'}">Adult Domains</span>
</div>
<app-dns-zone [zone]="currentZone()"></app-dns-zone>