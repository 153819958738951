import {Signal} from "@angular/core";

export class SignalMap<T=any> {

    private map = new Map<string,Signal<T>>;

    has(name: string): boolean {
        return this.map.has(name);
    }

    get(name: string): Signal<T>|undefined {
        return this.map.has(name)? this.map.get(name) : undefined;
    }

    set(name: string, value: Signal<T>) {
        this.map.set(name, value);
    }

    delete(name: string): boolean {
        return this.map.delete(name);
    }

}