import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./pages/login/login.component";
import {AuthenticatedGuard} from "./guards/authenticated.guard";
import {DomainComponent} from "./pages/domain/domain.component";
import {TransactionsComponent} from "./pages/transactions/transactions.component";
import {SettingsComponent} from "./pages/settings/settings.component";
import {ForgotPasswordComponent} from "./pages/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";
import {AdminComponent} from "./pages/admin/admin.component";
import {UserSessionsComponent} from "./pages/admin/user-sessions/user-sessions.component";
import {BuyDomainComponent} from "./pages/domain/buy-domain/buy-domain.component";
import {DnsZonesComponent} from "./pages/dns-zones/dns-zones.component";
import {BulkComponent} from "./pages/bulk/bulk.component";

const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'login', redirectTo: '/' },
    { path: 'domains', component: DomainComponent, canActivate: [AuthenticatedGuard] },
    { path: 'domains/buy/:domain', component: BuyDomainComponent, canActivate: [AuthenticatedGuard]},
    { path: 'domains/:domain', component: DomainComponent, canActivate: [AuthenticatedGuard] },
    { path: 'bulk', component: BulkComponent, canActivate: [AuthenticatedGuard] },
    { path: 'dns-zones', component: DnsZonesComponent, canActivate: [AuthenticatedGuard]},
    { path: 'transactions', component: TransactionsComponent, canActivate: [AuthenticatedGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthenticatedGuard] },
    { path: 'admin', component: AdminComponent, canActivate: [AuthenticatedGuard]},
    { path: 'admin/sessions', component: UserSessionsComponent, canActivate: [AuthenticatedGuard]},
    { path: 'forgot-password', component: ForgotPasswordComponent},
    { path: 'reset-password/:token', component: ResetPasswordComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabledBlocking',
            anchorScrolling: 'enabled'
        })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
