import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {MatButton, MatMiniFabButton} from '@angular/material/button';


const DEFAULT_TITLE = "Confirmation";
const DEFAULT_QUESTION = "Are you sure?";
const DEFAULT_YES_ANSWER = "Yes";
const DEFAULT_NO_ANSWER = "No";

@Component({
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogTitle, MatMiniFabButton, MatDialogContent, MatDialogActions, MatButton]
})
export class ConfirmDialogComponent {

    title: string = DEFAULT_TITLE;
    question: string = DEFAULT_QUESTION;
    yesAnswer: string = DEFAULT_YES_ANSWER;
    noAnswer: string = DEFAULT_NO_ANSWER;

    constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
        this.title = this.data.title? this.data.title : DEFAULT_TITLE;
        this.question = this.data.question? this.data.question : DEFAULT_QUESTION;
        this.yesAnswer = this.data.yesAnswer? this.data.yesAnswer : DEFAULT_YES_ANSWER;
        this.noAnswer = this.data.noAnswer? this.data.noAnswer : DEFAULT_NO_ANSWER;
    }

    answer(flag:boolean) {
        this.dialogRef.close(flag);
    }
}
