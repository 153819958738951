<h1>Transactions</h1>

<mat-radio-group id="range_type"
                 [value]="rangeType()"
                 (change)="changeRangeType($event)">
    <span id="range_type_label">Range:</span>
    <mat-radio-button value="custom">Custom</mat-radio-button>
    <mat-radio-button value="last_n">Last {{MAX_TRANSACTIONS}} transactions</mat-radio-button>
    <mat-radio-button value="last_30_days">Last 30 days</mat-radio-button>
    <mat-radio-button value="last_60_days">Last 60 days</mat-radio-button>
</mat-radio-group>

@if (users() && rangeType()=='custom') {
    <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-form-field appearance="outline">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [max]="today">
                <input matStartDate
                       placeholder="Start date"
                       required
                       formControlName="start">
                <input matEndDate
                       placeholder="End date"
                       required
                       formControlName="end">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>User</mat-label>
            <mat-select formControlName="user">
                @for (user of users(); track user.id) {
                    <mat-option [value]="user.id">
                        {{user.email}}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Operation</mat-label>
            <mat-select formControlName="operation">
                @for (op of operations; track op.id) {
                    <mat-option [value]="op.id">
                        {{op.description}}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <span class="button_area">
            <button mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="!formSignals.formValid() || isBusy()">Search</button>
        </span>
    </form>
}

<div [hidden]="isBusy() || transactions().length==0" class="paginator_row">
    <mat-paginator #paginator
                   [length]="transactions().length"
                   [pageIndex]="0"
                   [pageSize]="DEFAULT_PAGE_SIZE"
                   [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
</div>
<div id="table_scroller">
    @if (!isBusy() && transactions().length) {
        <table mat-table [dataSource]="dataSource()">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let trans" [innerHTML]="trans.transId"></td>
            </ng-container>

            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let trans" [innerHTML]="userEmail(trans.userId)"></td>
            </ng-container>

            <ng-container matColumnDef="op">
                <th mat-header-cell *matHeaderCellDef>Operation</th>
                <td mat-cell *matCellDef="let trans">{{trans.operation}}</td>
            </ng-container>

            <ng-container matColumnDef="started">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let trans">{{formatTimestamp(trans.startDate)}}</td>
            </ng-container>

            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef>Notes</th>
                <td mat-cell *matCellDef="let trans" [innerHTML]="linkDomains(trans.notes)"></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let trans">
                    <button mat-raised-button
                            class="detail_button"
                            color="primary"
                            (click)="showDetail(trans)">Detail</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row
                *matRowDef="let score; columns: columnsToDisplay"></tr>

        </table>
    }
</div>
@if (hasSearched() && transactions().length==0) {
    <div id="no_trans">No transactions in range</div>
}
