<h1>Settings</h1>

@if (!isBusy) {
    @if (!devices.length) {
        <div>
            <p class="info">
                You don't have any trusted devices. If you want to avoid having to enter the 2FA code every time you log in and
                you can trust that only you have access to that device, you can check the "Trust this device" box when you log
                in. After that the device will be trusted and you will see it listed in a table on this page.
            </p>
        </div>
    } @else {
        <div>
            <p class="info">
                Your trusted devices are shown in the table below. You can choose to forget a specific device from the list or
                all your trusted devices at once. Afterwards, you will have to use 2FA to authenticate at least once on each forgotten
                device.
            </p>

            <div id="table_scroller">
                <table mat-table [dataSource]="devices">

                    <ng-container matColumnDef="info">
                        <th mat-header-cell *matHeaderCellDef>Device Info</th>
                        <td mat-cell *matCellDef="let dev">{{dev.device_info}}</td>
                    </ng-container>

                    <ng-container matColumnDef="host">
                        <th mat-header-cell *matHeaderCellDef>Host</th>
                        <td mat-cell *matCellDef="let dev">{{dev.host}}</td>
                    </ng-container>

                    <ng-container matColumnDef="alias">
                        <th mat-header-cell *matHeaderCellDef>Alias</th>
                        <td mat-cell *matCellDef="let dev">{{dev.alias}}</td>
                    </ng-container>

                    <ng-container matColumnDef="updated">
                        <th mat-header-cell *matHeaderCellDef>Last used</th>
                        <td mat-cell *matCellDef="let dev">{{formatTimestamp(dev.updated)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let dev">
                            <button mat-icon-button
                                    title="Edit this device"
                                    color="primary"
                                    (click)="editDevice(dev)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button
                                    class="detail_button"
                                    color="accent"
                                    matTooltip="Forget this device"
                                    (click)="forgetDevice(dev)"><mat-icon>delete</mat-icon></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row
                        *matRowDef="let dev; columns: columnsToDisplay"></tr>

                </table>
            </div>

            <div id="forget_row">
                <button mat-raised-button color="warn" (click)="forgetTrustedDevices()">Forget All Trusted Devices</button>
            </div>
        </div>
    }
}