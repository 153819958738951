
<h1>Log In</h1>

<form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field>
        <input matInput
               formControlName="email"
               placeholder="Email">
        <mat-error>{{formSignals.errors('email')()}}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput
               formControlName="password"
               placeholder="Password"
               type="password">
        <mat-error>{{formSignals.errors('password')()}}</mat-error>
    </mat-form-field>

    @if (!isTrustedDevice()) {
        <div>
            <mat-form-field>
                <mat-label>2FA code</mat-label>
                <input matInput
                       formControlName="code"
                       placeholder="6-digit code">
                <mat-error>{{formSignals.errors('code')()}}</mat-error>
            </mat-form-field>

            <mat-checkbox formControlName="trustThisDevice">Trust this device</mat-checkbox>
        </div>
    }
    <div class="button_row">
        <button mat-raised-button
                color="primary"
                [disabled]="!canSubmit()"
                type="submit">Log In</button>
    </div>

    <div class="link" routerLink="/forgot-password">Forgot password?</div>

</form>
