import {ChangeDetectionStrategy, Component, computed, OnDestroy, OnInit, signal} from '@angular/core';
import {AdminService, AdminUser} from "../../services/admin.service";
import {BusyService} from "../../services/busy.service";
import {finalize} from "rxjs/operators";
import {AlertService} from "../../services/alert.service";
import {Constants} from "../../util/constants";
import {Subscription} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {CacheService} from "../../services/cache.service";
import {StateService} from "../../services/state.service";
import {MatDialog} from "@angular/material/dialog";
import {UserDialogComponent} from "../../dialogs/user-dialog/user-dialog.component";
import {Router} from "@angular/router";
import {ContactService} from "../../services/contact.service";
import {CacheMessageMapper} from "../../util/cache-message-mapper";
import {MatIcon} from '@angular/material/icon';
import {NgClass} from '@angular/common';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable
} from '@angular/material/table';
import {MatButton, MatIconButton} from '@angular/material/button';

@Component({
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButton, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, NgClass, MatIconButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow]
})
export class AdminComponent implements OnInit, OnDestroy {

    readonly columnsToDisplay = ['email', 'role', 'active', 'created', 'last_access', 'sessions', 'transactions', 'devices', 'actions'];

    isBusy = computed(() => this.busyService.isBusy());

    users = signal<AdminUser[]>([]);
    hasUsers = computed(() => this.users().length > 0);

    private notifSub?: Subscription;

    constructor(private adminService: AdminService,
                private alertService: AlertService,
                private notificationService: NotificationService,
                private cache: CacheService,
                private state: StateService,
                private dialog: MatDialog,
                private router: Router,
                private contactService: ContactService,
                private busyService: BusyService) {
    }

    ngOnInit() {
        this.loadUsers();
        this.notifSub = this.notificationService.message$.subscribe((message) => {
            const info = CacheMessageMapper.map(message, CacheMessageMapper.USERS_PAT);
            if (info) {
                this.cache.clear(...info.tags);
                console.log("users notification received: must reload user list...");
                this.reloadUsers();
            } else {
                console.log("no cache tag found for message:", message);
            }
        });
    }

    ngOnDestroy() {
        this.notifSub?.unsubscribe();
    }

    formatTimestamp(sd: string): string {
        return sd? Constants.US_TIMESTAMP_FORMAT.format(new Date(sd)) : "";
    }

    private loadUsers() {
        if (this.cache.has('users')) {
            this.users.set(this.cache.get('users'));
            return;
        }
        this.busyService.showBusy();
        this.adminService.getUsers().pipe(
            finalize(() => {this.busyService.showNotBusy()})
        ).subscribe({
            next: (users) => {
                this.users.set(users);
                this.cache.set('users', users);
            },
            error: (err) => {
                console.error(err);
                this.alertService.error("Error getting user list: " + err.message);
            }
        })
    }

    private reloadUsers() {
        this.users.set([]);
        this.loadUsers();
    }

    modifyUser(user: AdminUser) {
        if (!this.isCurrentUser(user)) {
            this.dialog.open(UserDialogComponent, {
                disableClose: true,
                data: user
            }).afterClosed().subscribe((out) => {
                console.log(out);
            });
        }
    }

    deleteUser(user: AdminUser) {
        this.alertService.confirm(`Are you sure you want to delete user ${user.email}?<br/>` +
                                    "All related records will be removed from the database.<br/>" +
                                    "This change can't be undone!")
            .subscribe((deleteIt) => {
                if (deleteIt) {
                    this.busyService.showBusy();
                    this.adminService.deleteUser(user.id).pipe(
                        finalize(() => {this.busyService.showNotBusy()})
                    ).subscribe({
                        next: () => {
                        },
                        error: (err) => {
                            console.error(err);
                            this.alertService.error(`Error deleting user with id ${user.id}: ${err.error || err.message}`);
                        }
                    });
                }
            });
    }

    addUser() {
        this.dialog.open(UserDialogComponent, {
            disableClose: true
        }).afterClosed().subscribe((out) => {
            console.log(out);
        });
    }

    isCurrentUser(user: AdminUser) {
        return user.id == this.state.get('userId')();
    }

    showUserSessions(user: AdminUser) {
        if (user.sessions == 0)
            return;
        this.state.setMany({selectedUserId: user.id, selectedUserEmail: user.email});
        this.router.navigateByUrl('/admin/sessions').then();
    }

    checkVerisignContact() {
        const registryName: string = "verisign";
        const uid: string = "ver-05889935";
        this.busyService.showBusy();
        this.contactService.isAvailable(registryName, uid).pipe(
            finalize(() => {this.busyService.showNotBusy()})
        ).subscribe({
            next: (available) => {
                this.alertService.info(`Contact with uid ${uid} is ${available? '': 'not '}available at ${registryName}.`)
            },
            error: (err) => {
                console.error(err);
                this.alertService.error("Error checking contact: " + (err.error || err.message));
            }
        });
    }

    addVerisignContact() {
        const contactId: number = 2;
        const registryName: string = "verisign";
        const uid: string = "ver-05889935";
        const authPassword: string = "716C1139C496A160";
        this.busyService.showBusy();
        this.contactService.create(contactId, registryName, uid, authPassword).pipe(
            finalize(() => {this.busyService.showNotBusy()})
        ).subscribe({
            next: () => {
                this.alertService.info("Registry contact successfully created!")
            },
            error: (err) => {
                console.error(err);
                this.alertService.error("Error creating contact: " + (err.error || err.message));
            }
        });
    }
}
