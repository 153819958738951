import {ChangeDetectionStrategy, Component, computed, OnInit, signal, viewChild} from '@angular/core';
import {StateService} from "../../../services/state.service";
import {AdminService, UserSession} from "../../../services/admin.service";
import {BusyService} from "../../../services/busy.service";
import {finalize} from "rxjs/operators";
import {UtilService} from "../../../services/util.service";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {Constants} from "../../../util/constants";
import {Router, RouterLink} from "@angular/router";

@Component({
    templateUrl: './user-sessions.component.html',
    styleUrls: ['./user-sessions.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, MatPaginator, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow]
})
export class UserSessionsComponent implements OnInit {

    private readonly userId: number;
    userEmail: string;

    dataSource = signal<MatTableDataSource<UserSession>|undefined>(undefined);
    readonly columnsToDisplay = ['id', 'pwa', 'expires', 'created', 'updated', 'closed'];
    readonly DEFAULT_PAGE_SIZE = 10;

    isBusy = computed(() => this.busyService.isBusy());

    paginator = viewChild<MatPaginator>('paginator');

    constructor(private state: StateService,
                private router: Router,
                private busyService: BusyService,
                private utilService: UtilService,
                private adminService: AdminService) {
        this.userId = this.state.get('selectedUserId')();
        this.userEmail = this.state.get('selectedUserEmail')();
        this.state.clear('selectedUserId', 'selectedUserEmail');
    }
    ngOnInit() {
        if (!this.userId)
            this.router.navigateByUrl('/admin').then();
        else {
            this.busyService.showBusy();
            this.adminService.getUserSessions(this.userId).pipe(
                finalize(() => { this.busyService.showNotBusy() })
            ).subscribe({
                next: (sessions) => {
                    // this.sessions = sessions;
                    this.dataSource.set(new MatTableDataSource<UserSession>(sessions));
                    if (this.paginator)
                        this.dataSource()!.paginator = this.paginator() || null;
                },
                error: this.utilService.getErrorHandler("Error getting user sessions")
            });
        }
    }

    hasPages = computed(() => {
        const ds = this.dataSource();
        if (!ds)
            return false;
        return !!ds.data && ds.data.length > this.DEFAULT_PAGE_SIZE;
    });

    formatTimestamp(ts: number): string {
        return ts? Constants.US_TIMESTAMP_FORMAT.format(new Date(ts)) : "";
    }

}
