import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PathService} from "./path.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OkInfo} from "../util/interfaces";

export interface AdminUser {
    id: number;
    email: string;
    role: string;
    active: boolean;
    created: number;
    last_access: number;
    sessions: number;
    transactions: number;
    devices: number;
}

export interface UserSession {
    id: number;
    pwa: boolean|null;
    expires: number;
    created: number;
    updated: number|null;
    closed: number|null;
}

interface UserList {
    users: AdminUser[];
}

interface SessionList {
    sessions: UserSession[];
}

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    private readonly adminRoot: string;

    constructor(private http: HttpClient,
                private pathService: PathService) {
        this.adminRoot = `${this.pathService.apiRoot}/admin`;
    }

    getUsers(): Observable<AdminUser[]> {
        return this.http.get<UserList>(`${this.adminRoot}/users`).pipe(
            map((info) => info.users)
        );
    }

    updateUser(userId: number, email?: string, active?: boolean, newRole?: string): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.adminRoot}/users/${userId}`, {
            email: email || null,
            active: active === undefined? null : active,
            role: newRole || null
        });
    }

    addUser(email: string, active: boolean, role: string): Observable<OkInfo> {
        return this.http.post<OkInfo>(`${this.adminRoot}/users`, {
            email: email,
            active: active,
            role: role
        });
    }

    deleteUser(userId: number): Observable<OkInfo> {
        return this.http.delete<OkInfo>(`${this.adminRoot}/users/${userId}`);
    }

    getUserSessions(userId: number): Observable<UserSession[]> {
        return this.http.get<SessionList>(`${this.adminRoot}/users/${userId}/sessions`).pipe(
            map((info) => info.sessions)
        );
    }
}
