import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StateService} from "../services/state.service";
import {SessionService} from "../services/session.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private state: StateService,
                private sessionService: SessionService) {
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token: string|undefined = this.state.get<string>('token')();
        if (token) {
            this.sessionService.restartSession();
            req = req.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        return next.handle(req);
    }
}
