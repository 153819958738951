import {inject} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "../services/auth.service";

/** Makes sure there is a currently active user session (as of last interaction with server)
 */
export const AuthenticatedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthService);

    if (!authService.isLoggedIn()) {
        authService.redirectToLogIn(state.url);
        return false;
    }
    return true;
}
