<h1>Bulk Domain Operations</h1>
<p>Various operations that act on a list of domains</p>

<div class="op_row">
    <input type="file" class="hidden"
           (change)="setPickLoaded($event)"
           #setPickUpload>
    <button mat-raised-button color="primary"
            (click)="setPickUpload.click()">Set as Picks</button>
</div>

<div class="op_row">
    <input type="file" class="hidden"
           (change)="clearPickLoaded($event)"
           #clearPickUpload>
    <button mat-raised-button color="primary"
            (click)="clearPickUpload.click()">Clear as Picks</button>
</div>
