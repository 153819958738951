
<h1>Change Password</h1>

<form [formGroup]="form" (ngSubmit)="submit()">
    <div>
        <mat-form-field>
            <input matInput
                   placeholder="Email"
                   required
                   formControlName="email">
            <mat-error>{{formSignals.errors('email')()}}</mat-error>
        </mat-form-field>
    </div>
    <div>
        <aa-password-input label="Password"
                           [required]="true"
                           [hint]="passwordHint"
                           [errors]="formSignals.errors('password')()"
                           control_name="password"></aa-password-input>
    </div>
    <div>
        <aa-password-input id="password2_field"
                           label="Confirm password"
                           [required]="true"
                           [errors]="formSignals.errors('password2')()"
                           control_name="password2"></aa-password-input>
    </div>
    <div class="error" [innerHTML]="formSignals.formErrors()"></div>
    <div id="button_row">
        <button mat-raised-button
                class="button"
                type="submit"
                color="primary"
                [disabled]="!formSignals.formValid() || isBusy()">Change Password</button>
    </div>
</form>
